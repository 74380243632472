import React, { useState } from 'react'
import ReactMde from 'react-mde'
import ReactMarkdown from 'react-markdown'
import 'react-mde/lib/styles/css/react-mde-all.css'
// import 'react-mde/lib/styles/css/react-mde-editor.css'
// import 'react-mde/lib/styles/css/react-mde-toolbar.css'
// import 'react-mde/lib/styles/css/react-mde-preview.css'

const Editor = ({ text, onChange }) => {
  const [selectedTab, setSelectedTab] = useState('write')
  return (
    <ReactMde
      value={text}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown source={markdown} />)}
      childProps={{ writeButton: { tabIndex: -1 } }}
      toolbarCommands={[['image', 'link']]}
    />
  )
}

export default Editor
