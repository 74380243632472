import React from 'react'
import Grid from '@material-ui/core/Grid'
import NewButton from '../common/LinkButton'
import Spacer from '../common/Spacer'

const Dashboard = () => {
  return (
    <Grid>
      <Spacer />
      <h1>Dashboard</h1>
      <Spacer />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <NewButton to='/documents/create' title='Neues Dokument anlegen' icon='doc' />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <NewButton to='/tags/create' title='Neuen Tag anlegen' icon='tag' />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
