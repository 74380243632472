import React from 'react'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import Button from '../common/Button'
import Modal from '../common/NotificationModal'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import PermissionGroupForm from './FormFields'
import PermissionSelection from '../Permissions/PermissionSelection'
import PermissionGroups from '../common/ApiHandler/PermissionGroup'
import Users from '../common/ApiHandler/User'

import styled from 'styled-components'
import Comment from '../Permissions/PermissionItem'

const Wrapper = styled.div`
border: 1px solid lightgrey;
padding: 20px 20px 0px 20px;
`

const PermissionGroupEdit = ({ match, ...props }) => {
  const [permissiongroup, setPermissiongroup] = React.useState({
    title: '',
    permissions: []
  })

  const [errorNotification, setErrorNotification] = React.useState('')
  // eslint-disable-next-line
  const [users, setUsers] = React.useState([])
  const [selectedPermissions, setSelectedPermissions] = React.useState([])
  const [oldGroupPermissions, setOldGroupPermissions] = React.useState([])

  const [newUsers, setNewUsers] = React.useState([])
  const [oldUsers, setOldUsers] = React.useState([])

  /* Default: Create Mode */
  const [status, setStatus] = React.useState({
    create: true,
    update: false,
    success: false
  })

  const handlePermissionChange = async (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions(prevSelectedPermissions => [...prevSelectedPermissions, permission])
    }
  }

  const toggleSelectedUser = ({ id }) => {
    setNewUsers(prefState => {
      if (prefState.includes(id)) {
        return [...prefState].filter(elem => elem !== id)
      }
      return [...prefState, id]
    })
  }

  /* Switch to update mode and receive data */
  React.useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus(prevStatus => ({ ...prevStatus, update: true, create: false }))
      loadData()
    }
    loadUser()
    // eslint-disable-next-line
  }, [match])

  const loadData = async () => {
    const API = PermissionGroups({ id: '' })
    const res = await API.findOne({ id: match.params.id })

    setPermissiongroup(res)
    const originalPermissions = []
    const allPermissionData = res.permissions
    allPermissionData.forEach((permission) => {
      originalPermissions.push(permission.id)
    })
    setOldGroupPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
    const selectedUsers = []
    const allSelectedUser = res.users
    allSelectedUser.forEach((user) => {
      selectedUsers.push(user.id)
    })
    setOldUsers(selectedUsers)
    setNewUsers(selectedUsers)
    loadUser()
  }

  const loadUser = async () => {
    const UserApi = Users({})
    const allUsers = await UserApi.findAll()
    allUsers.forEach((user) => {
      user.title = user.company + ' ' + user.firstname + ' ' + user.lastname + ' '
    })
    setUsers(allUsers)
  }

  const submit = async () => {
    if (match.params.id !== undefined) {
      await save()
    } else {
      await create()
    }
  }

  const create = async () => {
    const API = PermissionGroups({ id: '' })
    const res = await API.create(permissiongroup)
    if (typeof res === 'object') {
      getAssignedPermissions().forEach(async (permissionId) => {
        await res.assignPermission(res.id, permissionId)
      })
      getAssignedUsers().forEach(async (userId) => {
        await res.assignUser(userId, res.id)
      })
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const save = async () => {
    const res = await permissiongroup.set(permissiongroup)
    if (typeof res === 'object') {
      getUnassignedPermissions().forEach(async (permissionId) => {
        await permissiongroup.unassignPermission(permissiongroup.id, permissionId)
      })
      getAssignedPermissions().forEach(async (permissionId) => {
        await permissiongroup.assignPermission(permissiongroup.id, permissionId)
      })
      getUnassignedUsers().forEach(async (userId) => {
        await permissiongroup.unassignUser(userId, permissiongroup.id)
      })
      getAssignedUsers().forEach(async (userId) => {
        await permissiongroup.assignUser(userId, permissiongroup.id)
      })
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const getUnassignedPermissions = () => {
    return oldGroupPermissions.filter(id => !selectedPermissions.includes(id))
  }

  const getAssignedPermissions = () => {
    return selectedPermissions.filter(id => !oldGroupPermissions.includes(id))
  }

  const getUnassignedUsers = () => {
    return oldUsers.filter(id => !newUsers.includes(id))
  }

  const getAssignedUsers = () => {
    return newUsers.filter(id => !oldUsers.includes(id))
  }

  const resetState = () => {
    setStatus({ create: true, success: false })
    window.location.reload()
  }

  const showDeletePermissionGroupConfirmation = () => {
    setStatus(prevStatus => ({ ...prevStatus, delete: true }))
  }

  const deletePermissionGroup = async () => {
    await permissiongroup.delete()
    window.location.replace('/#/permissiongroups')
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, success: false, delete: false }))
  }

  return (
    <Grid>
      {status.update && status.delete &&
        <Modal message='Berechtigungsgruppe wirklich löschen und zur Übersicht wechseln?' type='delete' close={e => closeModal()} title='Berechtigungsgruppe löschen?'>
          <Grid item xs={6}>
            <Button onClick={() => closeModal()}>Abbrechen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button type='delete' onClick={() => deletePermissionGroup()}>Löschen</Button>
          </Grid>
        </Modal>}
      {status.success && status.create &&
        <Modal title='Glückwunsch' message='Erfolgreich gespeichert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/permissionGroups'>Zu den Berechtigungsgruppen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/permissionGroups/create' onClick={() => resetState()}>Weitere Berechtigungsgruppen erstellen</Button>
          </Grid>
        </Modal>}
      {status.success && status.update &&
        <Modal title='Glückwunsch' message='Erfolgreich editiert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/permissionGroups'>Zu den Berechtigungsgruppen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/permissionGroups/create'>Neuen Berechtigungsgruppe erstellen</Button>
          </Grid>
        </Modal>}
      <Spacer height='40px' />
      <Grid container>
        <Grid item xs={8}>
          {status.create &&
            <h1>Neuen Berechtigungsgruppe anlegen</h1>}
          {status.update &&
            <h1>Berechtigungsgruppe bearbeiten</h1>}
        </Grid>
        <Grid item xs={4}>
          {status.update &&
            <Button onClick={() => showDeletePermissionGroupConfirmation()}>Berechtigungsgruppe löschen</Button>}
        </Grid>
      </Grid>

      <Spacer height='30px' />
      <PermissionGroupForm permissionGroup={permissiongroup} setPermissionGroup={setPermissiongroup}>
        <PermissionSelection defaultPermissions={selectedPermissions} onChange={handlePermissionChange} />
        <Spacer height='20px' />
        <Wrapper>
          {
            users.map((user) => {
              const isChecked = oldUsers.find(search => search === user.id) !== undefined
              return (
                <Comment key={user.id} comment={user} toggleSelected={toggleSelectedUser} isChecked={isChecked} />
              )
            })
          }
        </Wrapper>
        {errorNotification &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Spacer />
        <Button onClick={e => submit()}>
          {status.create &&
            <>Berechtigungsgruppe erstellen</>}
          {status.update &&
            <>Berechtigungsgruppe aktualisieren</>}
        </Button>
      </PermissionGroupForm>
    </Grid>
  )
}
export default PermissionGroupEdit
