import React from 'react'

const SvgTagNeu = props => (
  <svg viewBox='0 0 20 20' {...props}>
    <defs>
      <path
        d='M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7zM16 14h-3v3h-2v-3H8v-2h3V9h2v3h3v2z'
        id='tag-neu_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#tag-neu_svg__a'
      transform='translate(-2 -2)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgTagNeu
