import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavbar = styled.div`
margin-top: 40px;
max-width: 100%;
display: flex;
flex-direction: column;
`

const StyledNavLink = styled(NavLink)`
  height: 40px;
  width: 100%;
  background-color: #A8ADB0;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  font-style: normal;
  margin-bottom: 12px;
  .active-indicator {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 40px;
    width: 10px;
    background-color: rgb(34, 162, 209);
  }
  &.active {
    background-color: #30393f;
    .active-indicator {
      display: block;
    }
  }
`
const NavBar = () => {
  return (
    <StyledNavbar>
      <StyledNavLink
        key='navbuttonDashboard'
        to='/dashboard'
      >
        <span className='active-indicator' />
        Dashboard
      </StyledNavLink>
      <StyledNavLink
        key='navbuttonDocuments'
        to='/documents'
      >
        <span className='active-indicator' />
        Dokumente
      </StyledNavLink>
      <StyledNavLink
        key='navbuttonUsers'
        to='/users'
      >
        <span className='active-indicator' />
        Händler
      </StyledNavLink>
      <StyledNavLink
        key='navbuttonTags'
        to='/tags'
      >
        <span className='active-indicator' />
        Tag Editor
      </StyledNavLink>
      <StyledNavLink
        key='navbuttonPermissions'
        to='/permissions'
      >
        <span className='active-indicator' />
        Berechtigungen
      </StyledNavLink>
    </StyledNavbar>
  )
}

export default NavBar
