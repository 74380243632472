import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'

const EditFormFields = ({ permissionGroup, setPermissionGroup, children, errorNotification, ...restProps }) => {
  const handleChange = (event, field) => {
    let newVal
    if (field === 'salutation' | field === 'language') {
      newVal = event.value
    } else {
      newVal = event.target.value
    }
    setPermissionGroup({
      ...permissionGroup,
      [field]: newVal
    })
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextInput
          title='Titel:'
          onChange={e => handleChange(e, 'title')}
          value={permissionGroup.title}
        />
      </Grid>
      {children &&
        <Grid item xs={12}>
          {children}
          <Spacer />
        </Grid>}
    </Grid>
  )
}

EditFormFields.propTypes = {
  permissionGroup: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any,
  setPermissionGroup: PropTypes.any,
  errorNotification: PropTypes.string
}

export default EditFormFields
