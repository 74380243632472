import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import Session, { useSession } from '../common/ApiHandler/Session'
import LoadIndicator from '../common/LoadIndicator'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'

const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

const Background = styled.div`
  height: ${screenHeight};
  width: ${screenWidth};
  position: relative;
`

const StyledH1 = styled.h1`
  margin-top: 45px;
  text-align: center;
  margin-bottom: 15px;
  color: #FFF
`

const Button = styled.button`
  height: 34px;
  width: 400px;
  border-radius: 17px;
  background-color: rgba(255,255,255,0.8);
  border: none;
  font-size: 14px;
  letter-spacing: 0.55px;
  line-height: 16px;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -200px;
  margin-top: 45px;
  &:hover {
    cursor: pointer;
  }
`

const NotificationContainer = styled.div`
  padding-top: 150px;
  max-width: 500px;
  margin: auto;
`

const Login = (props) => {
  const [error, setError] = useState('')

  const {
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    logout,
    isLoading
  } = useAuth0()

  const { destroySession } = useSession()

  useEffect(() => {
    const getToken = async () => {
      const claims = await getIdTokenClaims()

      let token
      if (claims) {
        token = claims.__raw
      }
      if (token) {
        Session().start(token)
      }
    }
    if (isAuthenticated) {
      getToken()
    }
  }, [isAuthenticated, getIdTokenClaims])
  const languageValidation = (browserLanguage) => {
    const supportedLanguages = ['en', 'de', 'fr']
    let language = 'en'
    if (String(browserLanguage).lenght === 2) {
      language = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en'
    } else {
      try {
        language = String(browserLanguage).split('-')[0]
      } catch (err) {
      }
    }
    return language
  }

  useEffect(() => {
    const deleteSessionAndLogout = () => {
      destroySession()
      logout({ returnTo: window.location.origin })
    }
    if (window.localStorage.getItem('asab-session-token') === 'LOGOUT') {
      deleteSessionAndLogout()
    }
  }, [destroySession, logout])

  useEffect(() => {
    if (window.location.search.includes('error')) {
      const url = new URL(window.location.href)
      const errorDescription = url.searchParams.get('error_description')
      setError(errorDescription)
    }
  }, [])

  const loginAuth0 = async (loginLanguage) => {
    if (error !== '') {
      await logout({ returnTo: window.location.origin })
    } else {
      await loginWithRedirect({ language: loginLanguage })
    }
  }

  if (!isLoading) {
    const loginLanguage = languageValidation(navigator.language)
    return (
      <Background>
        <StyledH1>Willkommen</StyledH1>
        {error !== '' &&
          <Button onClick={async () => await loginAuth0(loginLanguage)}>Logout</Button>}
        {error === '' &&
          <Button onClick={async () => await loginAuth0(loginLanguage)}>Log In</Button>}
        {error !== '' &&
          <NotificationContainer>
            <Notification type='error' message={Translation(error)} />
          </NotificationContainer>}
      </Background>
    )
  } else {
    return (
      <Background>
        <LoadIndicator />
      </Background>
    )
  }
}

export default Login
