import translations from './Translation'

const Translation = (res) => {
  // var userLang = navigator.language || navigator.userLanguage
  // userLang = userLang.substring(0, userLang.indexOf('-'))
  const userLang = 'de'
  if (translations[userLang][res]) {
    const translatedRes = translations[userLang][res]
    return translatedRes
  } else {
    return res
  }
}

export default Translation
