import React, { useCallback } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'

import Translation from '../common/BasicTranslation'
import Modal from './NotificationModal'
import Button from '../common/Button'
import Permissions, { Permission } from '../common/ApiHandler/Permission'

import Notification from '../common/GeneralNotification'

import ModalConfirm from '../common/NotificationModal.js'

import Checkbox from '../common/FormElements/Checkbox'

import BulletImage from '../../assets/svg/Closed'

const StyledName = styled.div`
  padding-top: 12px;
  padding-left: 61px;
  cursor: pointer;
`

const StyledAbort = styled(Button)`
  margin-top: -10px;
  background-color: #A8ADB0;
  height: 40px;
  width: 460px;
`

const StyledConfirm = styled(Button)`
  margin-top: -10px;
  margin-left: 12px;
  height: 40px;
  width: 460px;
`

const HiddenImage = styled.div`
  float: left;
  overflow: hidden;
`
const StyledCheckboxWrapper = styled.div`
  margin-bottom: -10px;
`

const PermissionItem = ({ item, toggleSelected, isChecked }) => {
  const [errorNotification, setErrorNotification] = React.useState('')
  const [selectedPermissions, setSelectedPermissions] = React.useState([])
  const [status, setStatus] = React.useState({
    create: false,
    display: false,
    delete: false
  })
  const [permission, setPermission] = React.useState({
    title: '',
    parentId: ''
  })

  const checkLocation = useCallback(() => {
    let currentLocation = window.location.toString()
    const index = currentLocation.lastIndexOf('/') + 1
    currentLocation = currentLocation.slice(index, currentLocation.length)
    if (currentLocation === 'permissions') {
      setStatus(prevStatus => ({ ...prevStatus, display: true }))
    }
  }, [setStatus])

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, success: false, create: false, update: false, delete: false }))
  }

  const submit = async () => {
    permission.parentId = item.id
    let res = 'false'
    const API = Permissions(permission)
    res = await API.create(permission)
    if (typeof res !== 'object') {
      setErrorNotification(Translation(res))
    }
    closeModal()
  }

  const updatePermissionTitle = async () => {
    let res = false
    const API = Permission(permission)
    res = await API.set()
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  const enterUpdateState = () => {
    setStatus(prevStatus => ({ ...prevStatus, update: true }))
  }

  const add = async (id) => {
    if (selectedPermissions.includes(id)) {
      return
    }
    const updatedPermissions = [...selectedPermissions]
    updatedPermissions.push(id)
    await setSelectedPermissions(updatedPermissions)
  }

  const remove = async (id) => {
    const updatedPermissions = selectedPermissions.filter(item => item !== id)
    await setSelectedPermissions(updatedPermissions)
  }

  const handleChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  const handleUpdateChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  const handleCheckbox = (id, checked) => {
    toggleSelected({ id })
    if (checked) {
      add(id)
      return checked
    } else {
      remove(id)
      return checked
    }
  }

  const toggleDelete = () => {
    setStatus(prevStatus => ({ ...prevStatus, delete: true, update: false }))
  }

  const deletePermission = async () => {
    let res = false
    const API = Permission(item)
    res = await API.delete()
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  React.useEffect(() => {
    checkLocation()
    setPermission(item)
  }, [checkLocation, item])

  return (
    <Grid>
      {status.update &&
        <Modal title='Berechtigung bearbeiten oder löschen' close={e => closeModal()} boxtitle='Berechtigungstitel' handleChange={e => handleUpdateChange(e, 'title')} value={permission.title}>
          <Grid item xs={6}>
            <StyledAbort type='delete' onClick={e => toggleDelete()}>Löschen</StyledAbort>
          </Grid>
          <Grid item xs={6}>
            <StyledConfirm onClick={() => updatePermissionTitle()}>Änderung Speichern</StyledConfirm>
          </Grid>
        </Modal>}
      {status.delete &&
        <ModalConfirm message='Berechtigung wirklich löschen?' type='delete' close={e => closeModal()} title='Berechtigung löschen?'>
          <Grid item xs={6}>
            <Button onClick={() => submit()}>Abbrechen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button type='delete' onClick={e => deletePermission()}>Löschen</Button>
          </Grid>
        </ModalConfirm>}
      {status.create &&
        <Modal title='Neue Berechtigung erstellen' close={e => closeModal()} boxtitle='Berechtigungstitel' handleChange={e => handleChange(e, 'title')} value={permission.title} parentId={item.id}>
          <Grid item xs={6}>
            <StyledAbort onClick={() => closeModal()}>Abbrechen</StyledAbort>
          </Grid>
          <Grid item xs={6}>
            <StyledConfirm onClick={() => submit()}>Erstellen</StyledConfirm>
          </Grid>
        </Modal>}
      {status.display &&
        <div>
          <Spacer height='40px' />
          <HiddenImage>
            <BulletImage height='40px' width='40px' />
          </HiddenImage>
          <StyledName onClick={e => enterUpdateState()}>{item.title}</StyledName>
        </div>}
      {!status.display &&
        <StyledCheckboxWrapper>
          <Checkbox onChange={() => handleCheckbox(item.id, !isChecked)} title={item.title} value={item.id} isChecked={isChecked} toggleSelected={toggleSelected} />
        </StyledCheckboxWrapper>}
      <Spacer height='10px' />
      {errorNotification !== '' &&
        <div>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </div>}
    </Grid>
  )
}

export default PermissionItem
