import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import OverviewTableItem from './OverviewTableItem'

const StyledTable = styled.div`
  table {
    margin-top: 5px;
    border-spacing: 0;
    border: none;
    width: 100%;
    table-layout: fixed;
    th, td {
      margin: 0;
      font-size: 14px;
      line-height: 28px;
      vertical-align:top;
      box-sizing: border-box;
      padding: 0px 0px 0px 15px;
    }
    thead {
      tr {
        th {
          width: 25%;
          border-bottom: 1px solid #979797;
          text-align: left;
          padding: 7px 0px 5px 15px;
          font-family: "Open Sans SemiBold", sans-serif;
          color: #30393F;
          cursor: pointer;
        }
      }
    }
    tbody {
      tr {
        :nth-child(odd) {
          background-color: #E7F7FF;
        }
        td {
          font-family: "Open Sans", sans-serif;
          line-height: 40px;
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .pagination {
    display: none;
  }
`

const OverviewTable = ({ users, options }) => {
  const [sortedBy, setSortedBy] = useState({})
  const sortUsers = (users, property) => {
    if (sortedBy[property]) {
      users.reverse()
    } else {
      if (property === 'company') {
        users.sort(function (a, b) {
          if (!a[property] || a[property] === null) {
            return -1
          }
          if (!b[property] || b[property] === null) {
            return 1
          }
          const valueA = a[property].toLowerCase(); const valueB = b[property].toLowerCase()
          if (valueA < valueB) { return -1 }
          if (valueA > valueB) { return 1 }
          return 0
        })
      } else if (property === 'createdAt') {
        users.sort(function (a, b) {
          const dateA = new Date(a[property]); const dateB = new Date(b[property])
          return dateA - dateB
        })
      } else if (property === 'customer_no') {
        users.sort(function (a, b) {
          if (!a[property] || a[property] === null) {
            return -1
          }
          if (!b[property] || b[property] === null) {
            return 1
          }
          const valueA = parseInt(a[property]); const valueB = parseInt(b[property])
          if (valueA < valueB) { return -1 }
          if (valueA > valueB) { return 1 }
          return 0
        })
      } else {
        users.sort(function (a, b) {
          const valueA = a[property]; const valueB = b[property]
          if (valueA < valueB) { return -1 }
          if (valueA > valueB) { return 1 }
          return 0
        })
      }
    }
    setSortedBy({ [property]: true })
  }

  const checkSortedBy = async () => {
    if (!sortedBy.keys) {
      users.sort(function (a, b) {
        const dateA = new Date(a.createdAt); const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
      setSortedBy({ createdAt: true })
    }
  }

  useEffect(() => {
    checkSortedBy()
    // eslint-disable-next-line
  }, [])

  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            <th onClick={e => sortUsers(users, 'customer_no')}>Kundennummer</th>
            <th onClick={e => sortUsers(users, 'company')}>Firma</th>
            <th onClick={e => sortUsers(users, 'firstname')}>Vorname</th>
            <th onClick={e => sortUsers(users, 'lastname')}>Nachname</th>
            <th onClick={e => sortUsers(users, 'customPermissionsAssigned')}>Status</th>
            <th onClick={e => sortUsers(users, 'createdAt')}>Registrierungsdatum</th>
          </tr>
        </thead>
        <tbody>
          {users.slice(0, options.pageSize).map((user, index) => {
            return (
              <OverviewTableItem key={`user-${user.id}-${index}`} user={user} />
            )
          })}
        </tbody>
      </table>
    </StyledTable>
  )
}

OverviewTable.propTypes = {
  users: PropTypes.any,
  options: PropTypes.any
}

export default OverviewTable
