import Api from './Api'

const api = Api()

export const Permission = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    set: async function () {
      const res = await api.exec(`/permissions/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        return res.error
      }
      return Permission(res)
    },
    assignDocument: async (documentId, id) => {
      const res = await api.exec(`/permissions/${id}/documents/${documentId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            permissionid: id,
            documentid: documentId
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignDocument: async (documentId, id) => {
      const res = await api.exec(`/permissions/${id}/documents/${documentId}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            permissionid: id,
            documentid: documentId
          })
        })
      if (res.error) return res.error
      return res
    },
    assignUser: async (userId, id) => {
      const res = await api.exec(`/permissions/${id}/users/${userId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            permissionid: id,
            uderid: userId
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignUser: async (userId, id) => {
      const res = await api.exec(`/permissions/${id}/users/${userId}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            permissionid: id,
            uderid: userId
          })
        })
      if (res.error) return res.error
      return res
    },
    delete: async () => {
      const res = await api.exec(`/permissions/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    }
  }
}

export const Permissions = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    create: async (permission) => {
      const res = await api.exec('/permissions', {
        method: 'POST',
        body: JSON.stringify({
          title: permission.title,
          parentId: permission.parentId
        })
      })
      if (res.error) {
        return res.error
      }
      return Permission(res.permission)
    },
    search: async (params) => {
      const res = await api.exec('/permissions', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (res.permissions.length < 1) {
        return []
      }
      return res.permissions.map(permission => Permission(permission))
    }
  }
}

export default Permissions
