import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledWrapperSearch = styled.div`
  position: relative;
  width: 100%
`

const SearchWrapper = ({ children }) => {
  return (
    <StyledWrapperSearch>
      {children}
    </StyledWrapperSearch>
  )
}

SearchWrapper.propTypes = {
  children: PropTypes.any
}

export default SearchWrapper
