import React from 'react'

const SvgTrash = props => (
  <svg viewBox='0 0 14 18' {...props}>
    <defs>
      <path
        d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z'
        id='trash_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#trash_svg__a'
      transform='translate(-5 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgTrash
