import React from 'react'
import Permissions from '../common/ApiHandler/Permission'
import Modal from '../common/NotificationModal'
import Button from '../common/Button'
import Translation from '../common/BasicTranslation'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import FormFields from './FormFields'
import Notification from '../common/GeneralNotification'

const PermissionEdit = ({ match, ...props }) => {
  const [permission, setPermission] = React.useState({
    title: '',
    parentid: ''
  })

  const [errorNotification, setErrorNotification] = React.useState('')

  const handleChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  /* Load Data for Update */
  const loadData = async () => {
    const currentPermission = Permissions({ id: match.params.id })
    const foundPermission = await currentPermission.findOne()
    setPermission(foundPermission)
  }

  const [status, setStatus] = React.useState({
    create: false,
    update: false,
    success: false
  })

  React.useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus(prevStatus => ({ ...prevStatus, update: true, create: false }))
      loadData()
    }
    // eslint-disable-next-line
    }, [match])

  const submit = async () => {
    let res = false
    if (!permission.id) {
      const API = Permissions(permission)
      res = await API.create()
    } else {
      const updatedPermission = { ...permission }
      delete updatedPermission.referenceid
      res = await updatedPermission.save()
    }
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const resetState = () => {
    setStatus({ create: true, success: false })
    window.location.reload()
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, success: false }))
  }

  // const deletePermission = async () => {
  //   const res = await Permission({ id: permission.Id }).destroy()
  //   if (typeof res === 'object') {
  //     await setStatus(prevStatus => ({ ...prevStatus, success: true, delete: false}))
  //   } else {
  //     await setErrorNotification(Translation(res))
  //   }
  // }

  return (
    <Grid>
      {status.success && status.create &&
        <Modal title='Glückwunsch' message='Erfolgreich gespeichert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/permissions'>Zu den Entwürfen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/permissions/create' onClick={() => resetState()}>Weitere Berechtigungen erstellen</Button>
          </Grid>
        </Modal>}
      {status.success && status.update &&
        <Modal title='Glückwunsch' message='Erfolgreich editiert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/permissions'>Zu den Berechtigungen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/permissions/create'>Neue Berechtigungen erstellen</Button>
          </Grid>
        </Modal>}
      <Spacer height='40px' />
      {status.create &&
        <h1>Neue Berechtigung anlegen</h1>}
      {status.update &&
        <h1>Berechtigung bearbeiten</h1>}
      <Spacer height='30px' />
      <FormFields permission={permission} setPermission={setPermission} handleChange={handleChange} />
      {errorNotification !== '' &&
        <div>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </div>}
      <Button onClick={e => submit()}>
        {status.create &&
          <>Berechtigung erstellen</>}
        {status.update &&
          <>Berechtigung aktualisieren</>}
      </Button>
    </Grid>
  )
}
export default PermissionEdit
