import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DocAction, DocActionWrapper } from './OverviewItemActions'
import Modal from '../common/NotificationModal'
import Grid from '@material-ui/core/Grid'
import Button from '../common/Button'
import IconImage from '../../assets/svg/Foto'

const DocItem = styled.div`
  margin: 1px;
  position: relative;
  height: 180px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 10px;
  div${DocActionWrapper} {
    display: none;
  }
  &:hover {
    div${DocActionWrapper} {
      display: block;
    }
  }
`
const DocImage = styled.img`
  max-width: 100%;
  height: 150px;
  max-height: 150px;
`

const PlaceholderImage = styled(IconImage)`
  position: absolute;
  height: 100px;
  top: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #939393;
`

const DocTitle = styled.div`
  padding-top: 10px;
`

const AddSelectionButton = styled.button`
  height: 100%;
  width: 100%;
  border: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  &:before {
    content: "";
    position: absolute;
    bottom: 12px;
    right: 12px;
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid #22a2d1;
    border-radius: 50%;
    background-color: #FFF;
  }
`
const RemoveSelectionButton = styled(AddSelectionButton)`
  &:before {
    background-color: #22a2d1;
  }
`

const Wrapper = styled.div`
  position: relative;
  height: 150px;
  outline: 1px solid #FFF;
  border: 1px solid #c7c7c7;
  &.selected {
    border: 1px solid #22a2d1;
    outline: 1px solid #22a2d1;
  }
`

const Badge = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  color: #000;
  background-color: ${props => props.type === 'park' ? '#73BFB8' : '#FEC601'};
`

Badge.propTypes = {
  type: PropTypes.string
}

const DocumentOverviewItem = ({ document, togglePark, remove, toggleSelection, selectionMode, selection, onChange }) => {
  const [modal, setModal] = useState({
    open: false,
    message: '',
    type: '',
    title: ''
  })

  const [thumbnail, setThumbnail] = useState()
  const [downloadUrl] = useState(document.getDownloadUrl())
  useEffect(() => {
    const cacheBuster = new Date(document.previewUpdatedAt || 0).getTime()
    document.getThumbnailUrl(cacheBuster)
      .then(thumbnailUrl => {
        setThumbnail(thumbnailUrl)
      })
      .catch(error => {
        console.error(error)
        setThumbnail()
      })
  }, [document, document.previewUpdatedAt])

  const toggleModal = () => {
    const updatedModal = { ...modal }
    updatedModal.open = updatedModal.open === false
    setModal(updatedModal)
  }

  const showModal = (type, message, title = '') => {
    const updatedModal = { ...modal }
    updatedModal.open = true
    updatedModal.type = type
    updatedModal.message = message
    updatedModal.title = title
    setModal(updatedModal)
  }

  const parkAndShowNotification = async (document) => {
    await togglePark(document)
    const statusAfterToggle = !document.draft
    if (statusAfterToggle) {
      showModal('park', 'Das Dokument wird den Benutzern nicht mehr angezeigt', 'Geparkt')
    } else {
      showModal('success', 'Das Dokument wird den Benutzern angezeigt', 'Veröffentlicht')
    }
  }

  const removeAndCloseModal = async (document) => {
    await remove(document)
    showModal('success', 'Das Dokument wurde erfolgreich gelöscht', 'Gelöscht')
  }

  const reloadAndCloseModal = () => {
    toggleModal()
    onChange()
  }

  const isSelected = (document) => {
    return selection.findIndex(doc => doc.id === document.id) !== -1
  }

  let title = 'Kein Titel gesetzt'
  if (document.title_de) {
    title = document.title_de
  } else if (document.title_en) {
    title = document.title_en
  } else if (document.title_it) {
    title = document.title_it
  } else if (document.title_fr) {
    title = document.title_fr
  }

  title = title.length > 40 ? title.slice(0, 37) + '...' : title
  return (
    <DocItem>
      {modal.open &&
        <Modal
          type={modal.type}
          title={modal.title}
          message={modal.message}
          close={toggleModal}
        >
          {modal.type === 'delete' &&
            <>
              <Grid item xs={6}>
                <Button onClick={() => removeAndCloseModal(document)}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => toggleModal()}>Nein</Button>
              </Grid>
            </>}
          {modal.type !== 'delete' && modal.title !== 'Gelöscht' &&
            <Grid item xs={12}>
              <Button onClick={e => toggleModal()}>Ok</Button>
            </Grid>}
          {modal.title === 'Gelöscht' &&
            <Grid item xs={12}>
              <Button onClick={e => reloadAndCloseModal()}>Ok</Button>
            </Grid>}
        </Modal>}
      <Wrapper className={selectionMode && isSelected(document) ? 'selected' : ''}>
        {document.draft &&
          <Badge type='park'>
            Geparkt
          </Badge>}
        {!selectionMode &&
          <DocActionWrapper>
            <DocAction title='Bearbeiten' icon='edit' to={`/documents/update/${document.id}`} />
            <DocAction title='Herunterladen' icon='download' href={downloadUrl} />
            {document.draft &&
              <DocAction title='Veröffentlichen' icon='park' onClick={e => parkAndShowNotification(document)} />}
            {!document.draft &&
              <DocAction title='Parken' icon='park' onClick={e => parkAndShowNotification(document)} />}
            <DocAction title='Löschen' icon='delete' onClick={() => showModal('delete', `Möchten Sie ${title} wirklich löschen?`, 'Löschen')} />
          </DocActionWrapper>}
        {thumbnail || (document.filePreviewGenerated || document.filePreviewPreviewGenerated || document.youtube) ? <DocImage src={thumbnail} /> : <PlaceholderImage fill='#939393' />}
        {selectionMode && isSelected(document) &&
          <RemoveSelectionButton onClick={() => toggleSelection(document)} />}
        {selectionMode && !isSelected(document) &&
          <AddSelectionButton onClick={() => toggleSelection(document)} />}
      </Wrapper>
      <DocTitle>
        {title}
      </DocTitle>
    </DocItem>
  )
}

DocumentOverviewItem.propTypes = {
  document: PropTypes.any,
  togglePark: PropTypes.any,
  remove: PropTypes.any,
  toggleSelection: PropTypes.any,
  selectionMode: PropTypes.bool,
  selection: PropTypes.array
}

export default DocumentOverviewItem
