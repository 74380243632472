import React from 'react'

const SvgBearbeiten = props => (
  <svg viewBox='0 0 18 18' {...props}>
    <defs>
      <path
        d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'
        id='bearbeiten_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#bearbeiten_svg__a'
      transform='translate(-3 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgBearbeiten
