import React from 'react'
import styled from 'styled-components'

const WrapperLabel = styled.label`
  padding-bottom: 20px;
  cursor: pointer;
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  background: ${props => (props.checked ? '#22a2d1' : '#fff')};
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const CheckboxLabel = styled.span`
  margin-left: 8px;
`

const Checkbox = ({ title, value, isChecked, onChange, ...props }) => {
  const [checked, setChecked] = React.useState(isChecked)

  React.useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleChange = () => {
    onChange()
    setChecked(!checked)
  }

  return (
    <WrapperLabel>
      <CheckboxContainer>
        <HiddenCheckbox onChange={() => handleChange(value)} checked={checked} />
        <StyledCheckbox defaultChecked={checked} checked={checked}>
          <Icon viewBox='0 0 24 24'>
            <polyline points='20 6 9 17 4 12' />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <CheckboxLabel>{title}</CheckboxLabel>
    </WrapperLabel>
  )
}

export default Checkbox
