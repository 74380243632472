import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import IconTrash from '../../assets/svg/Trash.js'
import IconSuccess from '../../assets/svg/Haken.js'
import IconCross from '../../assets/svg/Cross.js'
import IconCar from '../../assets/svg/Parken.js'
import IconUpload from '../../assets/svg/Upload.js'
import Grid from '@material-ui/core/Grid'
import Spacer from './Spacer'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const StyledModal = styled(Modal)`
  width: 440px;
  margin: 0 auto;
  background: #FFF;
  text-align: center;
  border: 1px solid black;
  padding: 20px;
  position: relative;
  border-radius: 6px;
  a {
    font-size: 12px;
  }
`

const ClosingButton = styled.button`
  border: 0;
  float: right;
  background-color: transparent;
`

const Foreground = styled.div`
  position: relative;
  z-index: 1;
`

const SuccessBackground = styled(IconSuccess)`
position: absolute;
width: 165px;
z-index: 0;
top: 113px;
left: 160px;
`
const TrashBackground = styled(IconTrash)`
position: absolute;
width: 127px;
z-index: 0;
top: 110px;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
`

const ParkBackground = styled(IconCar)`
position: absolute;
width: 127px;
z-index: 0;
top: 110px;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
`

const UploadBackground = styled(IconUpload)`
position: absolute;
width: 127px;
z-index: 0;
top: 110px;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
`

const NotificationModal = ({ type = 'success', title = '', message = '', close, children }) => {
  Modal.setAppElement('#root')
  return (
    <StyledModal
      onRequestClose={close}
      shouldCloseOnOverlayClick
      isOpen
      style={customStyles}
    >
      <Grid container spacing={0}>
        {type === 'success' &&
          <SuccessBackground fill='#E5E7E8' />}
        {type === 'delete' &&
          <TrashBackground fill='#E5E7E8' />}
        {type === 'park' &&
          <ParkBackground fill='#E5E7E8' />}
        {type === 'upload' &&
          <UploadBackground fill='#E5E7E8' />}
        {type !== 'upload' &&
          <>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <Foreground>
                <ClosingButton onClick={close}>
                  <IconCross height='18px' width='18px' fill='#A8ADB0' />
                </ClosingButton>
              </Foreground>
            </Grid>
          </>}
        <Grid item xs={12}>
          <Foreground>
            <Spacer height='75px' />
            <h1>{title}</h1>
            <Spacer height='30px' />
            <p>{message}</p>
            <Spacer height='90px' />
            <Grid container spacing={2}>
              {children}
            </Grid>
            <Spacer height='10px' />
          </Foreground>
        </Grid>
      </Grid>
    </StyledModal>
  )
}

NotificationModal.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default NotificationModal
