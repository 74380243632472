import React from 'react'

const SvgMehrfach = props => (
  <svg viewBox='0 0 20 20' {...props}>
    <defs>
      <path
        d='M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z'
        id='mehrfach_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#mehrfach_svg__a'
      transform='translate(-2 -2)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgMehrfach
