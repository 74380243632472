import React from 'react'

const SvgLupe = props => (
  <svg viewBox='0 0 18 18' {...props}>
    <defs>
      <path
        d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'
        id='lupe_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#lupe_svg__a'
      transform='translate(-3 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgLupe
