import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '../common/Button'
import Spacer from '../common/Spacer'
import DocumentOverviewItem from './OverviewItem'
import IconSelection from '../../assets/svg/Mehrfach'
import IconTrash from '../../assets/svg/Trash'
import IconPark from '../../assets/svg/Parken'
import Modal from '../common/NotificationModal'
import styled from 'styled-components'
import InfoBar from '../common/EntryInfoBar'
import VizSensor from 'react-visibility-sensor'

const DocumentOverview = ({ documents, onChange, onPublish, onUnpublish, onDestroy, loading }) => {
  const [selectionMode, setSelectionMode] = React.useState(false)
  const [selection, setSelection] = React.useState([])
  const [modal, setModal] = React.useState({})
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = React.useState(false)
  const [options, setOptions] = React.useState({
    pageSize: 0,
    increasePageSize: 30
  })

  const visibleEntryCount = documents.length > options.pageSize ? options.pageSize : documents.length

  const setPageSize = (pageSize) => {
    setOptions(prevOptions => ({ ...prevOptions, pageSize }))
  }

  const loadMore = () => {
    setPageSize(options.pageSize + options.increasePageSize)
  }
  /**
   * Lazy Load for Documents
   */
  React.useEffect(() => {
    if (!loading && loadMoreButtonVisible) {
      loadMore()
    }
    // eslint-disable-next-line
  }, [loadMoreButtonVisible])

  const showModal = (type, message, title = '') => {
    const updatedModal = { ...modal }
    updatedModal.open = true
    updatedModal.type = type
    updatedModal.message = message
    updatedModal.title = title
    setModal(updatedModal)
  }

  const toggleModal = () => {
    const updatedModal = { ...modal }
    updatedModal.open = updatedModal.open === false
    setModal(updatedModal)
  }

  const toggleSelection = (document) => {
    const selectionIndex = selection.findIndex(doc => doc.id === document.id)
    const updatedSelection = [...selection]
    if (selectionIndex === -1) {
      updatedSelection.push(document)
    } else {
      updatedSelection.splice(selectionIndex, 1)
    }
    setSelection(updatedSelection)
  }

  const toggleSelectionMode = () => {
    if (selectionMode === false) {
      setSelectionMode(true)
    } else {
      setSelection([])
      setSelectionMode(false)
    }
  }

  const togglePark = async (document) => {
    if (document.draft) {
      return onPublish(document)
    }
    return onUnpublish(document)
  }

  const batchAction = async (action, documents) => {
    if (documents.length > 0) {
      if (action === 'park') {
        for (const item of documents) {
          await togglePark(item)
        }
      }
      if (action === 'remove') {
        for (const item of documents) {
          await remove(item)
        }
      }
    }
  }

  const toggleParkBatchAndShowNotification = async () => {
    if (selection.length > 0) {
      await batchAction('park', selection)
      setSelection([])
      showModal('park', 'Dokumente erfolgreich geparkt oder veröffentlicht.', 'Veröffentlicht/Geparkt')
    }
  }

  const showDeleteConfirmation = async () => {
    if (selection.length > 0) {
      await showModal('delete', 'Wollen Sie wirklich ausgewählte Dokumente löschen?', 'Löschen')
    }
  }

  const deleteConfirmed = async () => {
    await batchAction('remove', selection)
    setSelection([])
    toggleModal()
    onChange()
  }

  const remove = async (document) => {
    try {
      await onDestroy(document)
    } catch (err) {
      console.error(err)
    }
  }
  const IconButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    float: right;
    padding: 10px 10px;
    margin-left: 5px;
    svg {
      width: 20px;
      height: 20px;
      fill: #A8ADB0;
    }
  `
  return (
    <Grid container spacing={0}>
      {modal.open &&
        <Modal type={modal.type} title={modal.title} message={modal.message} close={toggleModal}>
          {modal.type === 'park' &&
            <Grid item xs={12}>
              <Button onClick={() => toggleModal()}>Ok</Button>
            </Grid>}
          {modal.type === 'delete' &&
            <>
              <Grid item xs={6}>
                <Button onClick={() => deleteConfirmed()}>Ja</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => toggleModal()}>Nein</Button>
              </Grid>
            </>}
        </Modal>}
      <Grid item xs={6}>
        <Spacer height='40px' />
        <h3>Ihre Ergebnisse</h3>
      </Grid>
      <Grid item xs={6}>
        <Spacer height='40px' />
        <IconButton onClick={() => toggleSelectionMode()}>
          {!selectionMode &&
            <IconSelection />}
          {selectionMode &&
            <IconSelection fill='#22a2d1' height='20px' width='20px' />}
        </IconButton>
        {selectionMode &&
          <>
            <IconButton onClick={() => toggleParkBatchAndShowNotification()}><IconPark /></IconButton>
            <IconButton onClick={() => showDeleteConfirmation()}><IconTrash /></IconButton>
          </>}
        <Spacer height='20px' />
      </Grid>
      {!loading && Array.isArray(documents) && documents.length > 0 && documents.slice(0, options.pageSize).map((document, index) => {
        return (
          <Grid key={`${index}-${document.id}`} item xs={4} lg={2} md={3}>
            <DocumentOverviewItem
              document={document}
              togglePark={togglePark}
              remove={remove}
              toggleSelection={toggleSelection}
              selectionMode={selectionMode}
              selection={selection}
              onChange={onChange}
            />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        {!loading &&
          <InfoBar
            totalEntryCount={documents.length}
            visibleEntryCount={visibleEntryCount}
          />}
        <Spacer />
        {!loading && Array.isArray(documents) && documents.length > 0 && options.pageSize < documents.length &&
          <>
            <VizSensor onChange={(isVisible) => { setLoadMoreButtonVisible(isVisible) }}>
              <Button onClick={() => loadMore()}>Mehr Laden</Button>
            </VizSensor>
            <Spacer />
          </>}
      </Grid>

    </Grid>
  )
}

DocumentOverview.propTypes = {
  documents: PropTypes.any,
  reload: PropTypes.any,
  options: PropTypes.any
}

export default DocumentOverview
