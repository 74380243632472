import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import IconEdit from '../../assets/svg/Bearbeiten.js'
import IconDownload from '../../assets/svg/Download.js'
import IconPark from '../../assets/svg/Parken.js'
import IconDelete from '../../assets/svg/Trash.js'

const DocActionBaseStyle = `
  position: relative;
  display: block;
  float: left;
  width: 50%;
  height: 50%;
  background: transparent;
  max-width: 50%;
  max-height: 50%;
  cursor: pointer;
  z-index: 2;
  svg {
    height: 15px;
    width: 15px;
    fill: #FFF;
  }
  div {
    display: block;
    text-align: center;
  }
  &:hover {
    background-color: #22a2d1;
  }
`

const DocActionLink = styled(Link)`
  ${DocActionBaseStyle}
`

const DocActionButton = styled.button`
  ${DocActionBaseStyle}
`

const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  font-size: 10px;
  color: #FFF;
`

const IconWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
`

const StyledA = styled.a`
 position: relative;
  display: block;
  float: left;
  width: 50%;
  height: 50%;
  background: transparent;
  max-width: 50%;
  max-height: 50%;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;
    fill: #FFF;
  }
  div {
    display: block;
    text-align: center;
  }
  &:hover {
    background-color: #22a2d1;
  }
  z-index: 1;
`

const DocActionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  > a, button {
    padding: 0;
    border-style: solid;
    border-color: rgba(255,255,255, 0.5);
    box-sizing: border-box;
  }
  > a:nth-child(1), button:nth-child(1) {
    border-width: 0px 0px 0px 0px;
  }
  > a:nth-child(2), button:nth-child(2) {
    border-width: 0px 0px 0px 1px;
  }
  > a:nth-child(3), button:nth-child(3) {
    border-width: 1px 0px 0px 0px;
  }
  > a:nth-child(4), button:nth-child(4) {
    border-width: 1px 0px 0px 1px;
  }

`
const DocAction = ({ title, icon, ...restProps }) => {
  return (
    <>
      {restProps.to &&
        <DocActionLink {...restProps}>
          <IconWrapper>
            {icon === 'edit' &&
              <IconEdit />}
            {icon === 'download' &&
              <IconDownload />}
            {icon === 'park' &&
              <IconPark />}
            {icon === 'delete' &&
              <IconDelete />}
          </IconWrapper>
          <TextWrapper>{title}</TextWrapper>
        </DocActionLink>}
      {!restProps.to && !restProps.href &&
        <DocActionButton {...restProps}>
          <IconWrapper>
            {icon === 'edit' &&
              <IconEdit />}
            {icon === 'download' &&
              <IconDownload />}
            {icon === 'park' &&
              <IconPark />}
            {icon === 'delete' &&
              <IconDelete />}
          </IconWrapper>
          <TextWrapper>{title}</TextWrapper>
        </DocActionButton>}
      {restProps.href && !restProps.to &&
        <StyledA {...restProps}>
          <IconWrapper>
            {icon === 'edit' &&
              <IconEdit />}
            {icon === 'download' &&
              <IconDownload />}
            {icon === 'park' &&
              <IconPark />}
            {icon === 'delete' &&
              <IconDelete />}
          </IconWrapper>
          <TextWrapper>{title}</TextWrapper>
        </StyledA>}
    </>
  )
}

DocAction.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.string
}

export { DocAction, DocActionWrapper }
