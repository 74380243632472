import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import trash from '../../assets/svg/Trash.js'
import { Link } from 'react-router-dom'

const styles = `
  width: 100%;
  height: 40px;
  display: block;
  line-height: 40px;
  background-color: #22a2d1;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-family: 'Open Sans SemiBold', sans-serif;
  cursor: pointer;
  margin: 0px;
`

const StyledButton = styled.button`
${styles}
`
const StyledLink = styled(Link)`
${styles}
`

const StyledDelete = styled(StyledButton)`
  background-color: #A8ADB0;
`

const IconWrapper = styled.div`
  float: right;
`

const StyledTrash = styled(trash)`
  height: 22px;
  width: 16px;
  margin-top: 8px;
  margin-right: 12px;
`

const Button = ({ type, children, to = '', ...props }) => {
  if (type === 'cancel') {
    return (
      <StyledDelete {...props}>
        {children}
      </StyledDelete>
    )
  }
  if (type === 'delete') {
    return (
      <StyledDelete {...props}>
        <IconWrapper>
          <StyledTrash fill='#FFFF' />
        </IconWrapper>
        {children}
      </StyledDelete>
    )
  }
  if (to !== '') {
    return (
      <StyledLink to={to} {...props}>
        {children}
      </StyledLink>
    )
  }
  return (
    <StyledButton {...props}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  props: PropTypes.any,
  children: PropTypes.any
}

export default Button
