import React from 'react'

const SvgHaken = props => (
  <svg viewBox='0 0 18 14' {...props}>
    <defs>
      <path
        id='haken_svg__a'
        d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#haken_svg__a'
      transform='translate(-3 -5)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgHaken
