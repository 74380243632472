import React from 'react'
import Grid from '@material-ui/core/Grid'
import NewButton from '../common/LinkButton'
import Spacer from '../common/Spacer'
import styled from 'styled-components'

import PermissionGroups from '../common/ApiHandler/PermissionGroup'
import { StyledLink, SpanUnderline } from '../Tag/TagBase'
import LoadIndicator from '../common/LoadIndicator'

const Wrapper = styled.div`
  border: 1px solid #d1d1d1;
  background: #FFF;
  padding: 20px 20px 0px 20px;
`

const Overview = () => {
  const [permissiongroups, setPermissiongroups] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const loadData = async () => {
    setLoading(true)
    const permissionGroupSelection = await PermissionGroups({ id: '' })
    const allPermissionGroups = await permissionGroupSelection.search({})
    setPermissiongroups(allPermissionGroups)
    setLoading(false)
  }

  React.useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Spacer />
          <h1>Berechtigungsgruppen</h1>
        </Grid>
        <Grid item xs={6}>
          <Spacer />
          <NewButton title='Neue Berechtigungsgruppe erstellen' icon='user' to='/permissionGroups/create' />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Spacer height='40px' />
        <h3>Berechtigungsgruppen</h3>
        <Spacer height='20px' />
        {!loading &&
          <Wrapper>
            {!permissiongroups.length &&
              <h3>Keine Benutzergruppen verfügbar.</h3>}
            {permissiongroups.length &&
              <Grid container>
                {permissiongroups.map((permissiongroup, index) => {
                  return (
                    <Grid key={index} item xs={4}>
                      <StyledLink to={`/permissionGroups/update/${permissiongroup.id}`}>
                        <SpanUnderline>
                          {permissiongroup.title}
                        </SpanUnderline>
                      </StyledLink>
                    </Grid>
                  )
                })}
              </Grid>}
          </Wrapper>}
        {loading &&
          <LoadIndicator />}
      </Grid>
    </Grid>
  )
}

export default Overview
