import React from 'react'
import styled from 'styled-components'
import trash from '../../assets/svg/Reload.js'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'

const DefaultText = styled.p`
  font-size: 14px;
  display: inline-block;
`

const StyledTrash = styled(trash)`
  height: 20px;
  width: 20px;
  margin-top: 5px;
`

const ReloadButton = styled.div`
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  background-color: #22a2d1;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-family: 'Open Sans SemiBold', sans-serif;
  cursor: pointer;
  margin-top: -5px;
  margin-right: 40px;
  float: right;
`

const markAsNew = ({ document, setDocument }) => {
  const toggleMarkAsNew = () => {
    const newDate = new Date().toString()
    setDocument(prevDocument => ({ ...prevDocument, newSince: newDate }))
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        {document.newSince &&
          <DefaultText> Zuletzt als neu markiert: {moment(document.newSince).format('DD.MM.YYYY HH:mm')} </DefaultText>}
        {!document.newSince &&
          <DefaultText> Zuletzt als neu markiert: - </DefaultText>}
      </Grid>
      <Grid item xs={4}>
        <ReloadButton onClick={e => toggleMarkAsNew(e)}>
          <StyledTrash fill='#FFF' />
        </ReloadButton>
      </Grid>

    </Grid>
  )
}

export default markAsNew
