import Api from './Api'

const api = Api()

export const Document = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function (params) {
      const res = await api.exec(`/documents/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(params || this)
        })

      if (res.error) {
        return res.error
      }
      return Document(res.document)
    },
    getThumbnailUrl: async (cacheBuster) => {
      const res = await api.exec(`/documents/${id}/download/true?returnUrl=true${cacheBuster ? `&cachebuster=${cacheBuster}` : ''}`, {
        method: 'GET'
      })
      return res.targetUrl
    },
    getDownloadUrl: () => {
      return api.buildUrl(`/documents/${id}/download/false`)
    },
    destroy: async () => {
      const res = await api.exec(`/documents/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    },
    // download: async () => {
    //   await api.execFileDownload(`/download/${id}`)
    // },
    download: async (id) => {
      const res = await api.exec(`/documents/${id}/download/false`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
    },
    assignTag: async (tagId) => {
      const res = await api.exec(`/documents/${id}/tags/${tagId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            documentid: id,
            tagid: tagId
          })
        })
      if (res.error) return res.error
      return res
    },
    unassignTag: async (tagId) => {
      const res = await api.exec(`/documents/${id}/tags/${tagId}`,
        {
          method: 'DELETE',
          body: JSON.stringify({
            documentid: id,
            tagid: tagId
          })
        })
      if (res.error) return res.error
      return res
    }
  }
}

export const Documents = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    create: async () => {
      const res = await api.exec('/documents', {
        method: 'POST',
        body: JSON.stringify(restProps)
      })
      if (res.error) {
        return res.error
      }
      return Document(res.document)
    },
    upload: async (file, thumbnail, id) => {
      const uploadData = new FormData()
      uploadData.append('file', file)
      const res = await api.exec(`/documents/${id}/upload/${thumbnail}`, {
        method: 'POST',
        headers: {},
        body: uploadData
      })
      if (res.error) {
        return res.error
      }
      return Document(res)
    },
    findAll: async (params) => {
      const res = await api.exec('/documents', {
        method: 'GET',
        body: JSON.stringify(params)
      })

      if (res.error) {
        return res.error
      }
      if (res.documents.length < 1) {
        return []
      }
      return res.documents.map(document => Document(document))
    },
    findOne: async () => {
      const res = await api.exec(`/documents/${id}`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
      // quickfix @todo
      if (res.document.title_en === null) {
        res.document.title_en = ''
      }
      return Document(res.document)
    },
    destroy: async (id) => {
      const res = await api.exec(`/documents/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    }
  }
}

export default Documents
