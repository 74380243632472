import PropTypes from 'prop-types'
import styled from 'styled-components'

const Spacer = styled.div`
  height: ${props => props.height || '40px'};
  width: 100%;
`

Spacer.propTypes = {
  height: PropTypes.string
}

export default Spacer
