import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Checkbox from '../common/FormElements/Checkbox'
import { Wrapper, TabSelection, TabButton, Tab, TabContent, loadTags, splitToChunks } from './TagBase'
import Translation from '../common/BasicTranslation'
import LoadIndicator from '../common/LoadIndicator'

const TagSelection = ({ defaultTags, onChange }) => {
  /* Selected TAB */
  const [activeTab, setActiveTab] = useState(0)
  /* List of all tags */
  const [tags, setTags] = useState([])
  /* List of selected (checked) TAGS */
  const [selectedTags, setSelectedTags] = useState(defaultTags)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      setTags(await loadTags())
      setLoading(false)
    }
    loadData()
  }, [])

  // Set default selected tags
  useEffect(() => {
    setSelectedTags(defaultTags)
  }, [defaultTags])

  return (
    <div>
      {!loading &&
        <Wrapper>
          <TabSelection>
            {Object.keys(tags).sort().map((type, index) => {
              return (
                <TabButton key={index} onClick={() => setActiveTab(index)} className={(activeTab === index ? 'active' : '')}>{Translation(type)}</TabButton>
              )
            })}
          </TabSelection>
          <TabContent>
            {Object.keys(tags).sort().map((type, index) => {
              const sortedTags = tags[type].sort((a, b) => {
                const abbreviationA = a.abbreviation.toUpperCase()
                const abbreviationB = b.abbreviation.toUpperCase()
                return (abbreviationA < abbreviationB) ? -1 : (abbreviationA > abbreviationB) ? 1 : 0
              })
              const tagChunks = splitToChunks([...sortedTags], 3)
              if (activeTab === index) {
                return (
                  <Tab key={index} active={activeTab === index}>
                    <Grid container>
                      {tagChunks.map((chunk, index) => {
                        return (
                          <Grid key={index} item xs={4}>
                            {chunk.map((tag, index) => {
                              const isChecked = selectedTags.find(search => search === tag.id) !== undefined
                              return (
                                <Checkbox key={index} onChange={() => { onChange(tag.id) }} title={tag.abbreviation} value={tag.id} isChecked={isChecked} />
                              )
                            })}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Tab>
                )
              } else {
                return null
              }
            })}
          </TabContent>
        </Wrapper>}
      {loading &&
        <LoadIndicator />}
    </div>
  )
}

export default TagSelection
