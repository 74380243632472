import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Images
import IconUser from '../../assets/svg/NeuBenutzer.js'
import DocImage from '../../assets/svg/NeuDokument.js'
import TagImage from '../../assets/svg/TagNeu.js'

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  border: 1.5px solid #bdd3e1;
  height: 38px;
  line-height: 40px;
  color: #22a2d1;
  text-align: center;
  font-weight: bold;
  clear: left;
  cursor: pointer;
  font-size: 14px;
  border-radius: 2px;
  position: relative;
  text-decoration:none;
  font-family: "Open Sans Bold", sans-serif;
  float: right;
`

const StyledIconUser = styled.span`
  svg {
    position: absolute;
    left: 15px;
    top: 10px;
  }
  `
const StyledIconDoc = styled.span`
svg {
  position: absolute;
  left: 15px;
  top: 9px;
}
`

const StyledIconTag = styled.span`
svg {
  position: absolute;
  left: 15px;
  top: 9px;
}
`

function NewButton ({ title, icon, ...restProps }) {
  return (
    <StyledLink {...restProps}>
      {icon === 'user' &&
        <StyledIconUser>
          <IconUser height='18px' fill='#22a2d1' />
        </StyledIconUser>}
      {icon === 'doc' &&
        <StyledIconDoc>
          <DocImage height='20px' fill='#22a2d1' />
        </StyledIconDoc>}
      {icon === 'tag' &&
        <StyledIconTag>
          <TagImage height='20px' fill='#22a2d1' />
        </StyledIconTag>}
      {title}
    </StyledLink>
  )
}

NewButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
}

export default NewButton
