import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconCross from '../../assets/svg/Cross.js'

const StyledNotification = styled.div`
  padding: 18px 18px 18px 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  text-align: center;
`

const NeutralNotification = styled(StyledNotification)`
  border-color: #c0c0c0;
  background-color: #FFFFFF;
  color: #30393F;
`

const ErrorNotification = styled(StyledNotification)`
  border-color: #EBCCD1;
  background-color: #F2DEDE;
  color: #B76161;
`

const SuccessNotification = styled(StyledNotification)`
  border-color: #AEC7E0;
  background-color: #E7F7FF;
  color: #4283C1;
`

const ClosingButton = styled.button`
  border: 0;
  float: right;
  background-color: transparent;
  svg {
    height: 16px;
    width: 16px;
  }
`

const GeneralNotification = ({ type = 'neutral', message = '' }) => {
  const [isVisible, toggle] = useState(true)

  return (
    <div>
      {isVisible &&
        <div>
          {type === 'neutral' &&
            <NeutralNotification>
              {message}
              <ClosingButton onClick={() => toggle(false)}><IconCross fill='#30393F' /></ClosingButton>
            </NeutralNotification>}
          {type === 'error' &&
            <ErrorNotification>
              {message}
            </ErrorNotification>}
          {type === 'success' &&
            <SuccessNotification>
              {message}
              <ClosingButton onClick={() => toggle(false)}><IconCross fill='#4283C1' /></ClosingButton>
            </SuccessNotification>}
        </div>}
    </div>
  )
}

GeneralNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
}

export default GeneralNotification
