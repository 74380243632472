import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import SearchButton from '../common/FormElements/SearchButton'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import SearchWrapper from '../common/FormElements/SearchWrapper'
import PropTypes from 'prop-types'

const OverviewRefine = ({ users, setFilteredUsers, reload }) => {
  const [search, setSearch] = useState('')

  const setSearchInput = (event) => {
    if (event !== '') {
      setSearch(event.target.value)
      submitSearch(event.target.value)
    } else {
      setSearch('')
      submitSearch('')
      reload()
    }
  }

  const submitSearch = async (search) => {
    const result = []
    users.forEach(user => {
      if (user.complete.toUpperCase().includes(search.toUpperCase())) {
        result.push(user)
      }
    })
    setFilteredUsers(result)
  }

  const resetSearch = () => {
    setSearchInput('')
    reload()
  }

  return (
    <Grid>
      <Spacer />
      <SearchWrapper>
        <TextInput
          onChange={e => setSearchInput(e)}
          value={search}
          placeholder='Suche'
        />
        {search !== '' &&
          <SearchButton active onClick={() => resetSearch()} />}
      </SearchWrapper>
      <Spacer />
    </Grid>
  )
}

OverviewRefine.propTypes = {
  users: PropTypes.any,
  setUsers: PropTypes.any,
  reload: PropTypes.any
}

export default OverviewRefine
