module.exports = ({ documents = [], filter = {} }) => {
  return documents
    .filter(document => {
      if (!filter.title) {
        return true
      }
      let searchString = ''
      if (document.title_de) {
        searchString += document.title_de
      }
      if (document.title_en) {
        searchString += document.title_en
      }
      if (document.title_fr) {
        searchString += document.title_fr
      }
      if (document.title_it) {
        searchString += document.title_it
      }
      searchString = searchString.toLowerCase()
      const titleFilter = filter.title.toLowerCase()
      if (searchString.includes(titleFilter)) {
        return true
      }
      return false
    })
    .filter(({ productgroups }) => {
      const noProductFilterActive = !(filter.productgroups && filter.productgroups.length)
      const matchesFilter = productgroups ? productgroups.includes(filter.productgroups) : false
      if (noProductFilterActive || matchesFilter) {
        return true
      }
      return false
    })
    .filter(({ docarts }) => {
      const noProductFilterActive = !(filter.docarts && filter.docarts.length)
      const matchesFilter = docarts ? docarts.includes(filter.docarts) : false
      if (noProductFilterActive || matchesFilter) {
        return true
      }
      return false
    })
    .filter(({ draft }) => {
      const noProductFilterActive = filter.draft === undefined
      const matchesFilter = draft === filter.draft
      if (noProductFilterActive || matchesFilter) {
        return true
      }
      return false
    })
    .filter(({ languages }) => {
      const noProductFilterActive = !(filter.languages && filter.languages.length)
      const matchesFilter = languages ? languages.includes(filter.languages) : false
      if (noProductFilterActive || matchesFilter) {
        return true
      }
      return false
    })
    .filter(({ brands }) => {
      const noBrandsFilterActive = !(filter.brands && filter.brands.length)
      const matchesFilter = brands ? brands.includes(filter.brands) : false
      if (noBrandsFilterActive || matchesFilter) {
        return true
      }
      return false
    })
}
