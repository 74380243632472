import React, { useEffect, useState, useCallback } from 'react'
import Select from '../../common/FormElements/Select'
import Grid from '@material-ui/core/Grid'
import Spacer from '../../common/Spacer'
import Title from './Title'
import getAvailableFilters from './getAvailableFilters'

const FilterBar = ({ onChange, documents, filter }) => {
  const [availableFilters, setAvailableFilters] = useState({})
  const [filterLabels, setFilterLabels] = useState({})

  const selectFilter = useCallback((type, value) => {
    const newFilter = { ...filter }
    newFilter[type] = value
    if (value === '' && type !== 'title') {
      delete newFilter[type]
    }
    onChange(newFilter)
  }, [onChange, filter])

  const handleFilterSelection = (type) => (event) => {
    selectFilter(type, event.value)
  }

  const changeTitle = useCallback((title) => {
    selectFilter('title', title)
  }, [selectFilter])

  useEffect(() => {
    const currentFilters = getAvailableFilters(documents)
    setAvailableFilters(currentFilters)
  }, [documents])

  const loadSavedFilterPlaceholder = useCallback(() => {
    if (Object.keys(availableFilters).length > 0) {
      const newFilterLabels = {}
      const allSavedFilterKeys = Object.keys(filter)
      if (allSavedFilterKeys.length > 0) {
        for (const filterKey of allSavedFilterKeys) {
          if (Array.isArray(availableFilters[filterKey])) {
            for (const singleFilter of availableFilters[filterKey]) {
              if (singleFilter.value === filter[filterKey] && singleFilter.value !== '') {
                newFilterLabels[filterKey] = singleFilter.label
              }
            }
          }
        }
      }
      setFilterLabels(newFilterLabels)
    }
  }, [availableFilters, filter])

  useEffect(() => {
    loadSavedFilterPlaceholder()
  }, [filter, availableFilters, loadSavedFilterPlaceholder])

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Spacer />
          <Title title={filter.title} onChange={changeTitle} />
          <Spacer height='10px' />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <Select
            onChange={handleFilterSelection('languages')}
            value={filter.languages ? undefined : null}
            placeholder={filterLabels.languages || 'Sprache'}
            name='languageOption'
            options={availableFilters.languages}
          />
        </Grid>
        <Grid item xs>
          <Select
            onChange={handleFilterSelection('productgroups')}
            value={filter.productgroups ? undefined : null}
            placeholder={filterLabels.productgroups || 'Produktgruppe'}
            name='productOptions'
            options={availableFilters.productgroups}
          />
        </Grid>
        <Grid item xs>
          <Select
            onChange={handleFilterSelection('docarts')}
            value={filter.docarts ? undefined : null}
            placeholder={filterLabels.docarts || 'Dokumentenart'}
            name='docartOptions'
            options={availableFilters.docarts}
          />
        </Grid>
        <Grid item xs>
          <Select
            onChange={handleFilterSelection('draft')}
            value={filter.draft || filter.draft === false ? undefined : null}
            placeholder={filterLabels.draft || 'Status'}
            name='statusOptions'
            options={availableFilters.draft}
          />
        </Grid>
        <Grid item xs>
          <Select
            onChange={handleFilterSelection('brands')}
            value={filter.brands ? undefined : null}
            placeholder={filterLabels.brands || 'Marke'}
            name='brandsOptions'
            options={availableFilters.brands}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FilterBar
