import React from 'react'

const SvgClosed = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <title>closed</title>
    <path
      d='M0 0h40v40H0V0zm35 15H15v20l20-20z'
      fill='#A8ADB0'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgClosed
