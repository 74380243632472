import React from 'react'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ArrowDown from '../../assets/svg/ArrowDown'
import Spacer from '../common/Spacer'
import TextInput from '../common/FormElements/TextInput'
import FormControl from '@material-ui/core/FormControl'
import Select from '../common/FormElements/Select'
import SelectionTitle from '../common/FormElements/SelectionTitle'
import Dropzone from '../common/FormElements/Dropzone'
import Notification from '../common/GeneralNotification'
import Flag from '../common/Flag'
import { Tags, Tag } from '../common/ApiHandler/Tag'
import Button from '../common/Button'
import Editor from '../common/Editor'
import Translation from '../common/BasicTranslation'
import Modal from '../common/NotificationModal'
import config from '../common/ApiHandler/Config'

const EditForm = (props) => {
  const tagDataInit = {
    title_de: '',
    title_en: '',
    title_fr: '',
    title_it: '',
    description_de: '',
    description_en: '',
    description_fr: '',
    description_it: '',
    abbreviation: '',
    short_id: ''
  }
  const [tag, setTag] = React.useState(tagDataInit)
  const [tagCategories, setTagCategories] = React.useState([])
  const [status, setStatus] = React.useState({
    create: false,
    update: false,
    success: false,
    delete: false,
    showModal: false
  })
  const [errorNotification, setErrorNotification] = React.useState('')

  const { match } = props

  let itemId = 0

  if (match.params.id && match.params.id !== '') {
    itemId = match.params.id
  }

  React.useEffect(() => {
    if (itemId !== 0) {
      loadData()
      setStatus(prevStatus => ({ ...prevStatus, update: true }))
    } else {
      setStatus(prevStatus => ({ ...prevStatus, create: true }))
    }
    getItemTypes()
    // eslint-disable-next-line
  }, [])

  const getItemTypes = async () => {
    const apiHandler = Tags({ id: '' })
    const allItems = await apiHandler.findAll({})
    const itemTypes = []
    allItems.forEach((element) => {
      if (undefined === itemTypes.find(type => type.value === element.type)) {
        itemTypes.push({ label: Translation(element.type), value: element.type })
      }
    })
    setTagCategories(itemTypes)
  }

  const loadData = async () => {
    const apiHandler = Tags({ id: itemId })
    const item = await apiHandler.findOne()
    if (item.error) {
      return setErrorNotification(Translation(item.error))
    }
    // make sure that values are not null
    Object.keys(tagDataInit).forEach(key => {
      if (!item[key]) {
        item[key] = ''
      }
    })
    setTag(item)
  }

  const handleMissingTitle = (error) => {
    return error.match(/^title_([a-z]{2})(.{40})$/)
  }

  const submit = async () => {
    setErrorNotification('')
    try {
      const apiHandler = Tags(tag)
      if (itemId === 0) {
        const res = await apiHandler.create()
        if (res.error && handleMissingTitle(res.error)) {
          return setErrorNotification(Translation('missing title'))
        } else if (res.error) {
          return setErrorNotification(Translation(res.error))
        }
        if (tag.image) {
          const { error } = await apiHandler.upload(tag.image, res.id)
          if (error) {
            const newTag = Tag({ id: res.id })
            await newTag.destroy()
            return setErrorNotification(Translation(error))
          }
        }
        setStatus(prevStatus => ({ ...prevStatus, success: true, showModal: true }))
      } else {
        const res = await tag.save()
        if (res.error) {
          return setErrorNotification(Translation(res.error))
        }
        if (tag.image) {
          const { error } = await apiHandler.upload(tag.image, tag.id)
          if (error) {
            return setErrorNotification(Translation(error))
          }
        }
        setStatus(prevStatus => ({ ...prevStatus, success: true, showModal: true }))
      }
    } catch (error) {
      setErrorNotification(Translation((typeof error !== 'string') ? error.message : error))
      console.log(error)
    }
  }

  const showDelete = async () => {
    setStatus(prevStatus => ({ ...prevStatus, delete: true, showModal: true }))
  }

  const deleteConfirmed = async () => {
    const res = await tag.destroy()
    if (res.error) {
      setStatus(prevStatus => ({ ...prevStatus, success: false, showModal: false }))
      return setErrorNotification(Translation(res.error))
    }
    setStatus(prevStatus => ({ ...prevStatus, success: true, update: false, delete: true, showModal: true }))
  }

  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, showModal: false }))
  }

  const resetState = () => {
    setStatus({ create: true, success: false, delete: false, showModal: false })
    window.location.reload()
  }

  const handleSwitchChange = (event, field) => {
    setTag(prevTag => ({
      ...prevTag,
      [field]: event.value
    }))
  }

  const handleChange = (event, field) => {
    setTag(prevTag => ({
      ...prevTag,
      [field]: event.target.value
    }))
  }

  const handleTextChange = field => text => {
    setTag(prevTag => ({
      ...prevTag,
      [field]: text
    }))
  }

  const handleDropzoneChange = async (field, acceptedFiles) => {
    try {
      const file = acceptedFiles.length ? acceptedFiles[0] : undefined
      setTag(prevTag => ({
        ...prevTag,
        [field]: file
      }))
    } catch (err) {
      console.error(err)
    }
  }

  const button = (type) => {
    switch (type) {
      case 'overview':
        return <Button to='/tags'>Zur Übersicht</Button>
      case 'new':
        return <Button to='/tags/create' onClick={() => resetState()}>Neuen Tag erstellen</Button>
      case 'confirmDelete':
        return <Button onClick={async () => deleteConfirmed()}>Ja</Button>
      case 'closeModal':
        return <Button onClick={() => closeModal()}>Nein</Button>
      default:
        return true
    }
  }

  const isProductGroup = tag && tag.type === 'productgroup'

  return (
    <Grid>
      {status.delete && !status.success && status.showModal &&
        <Modal type='delete' title='Löschen' message={`Wollen Sie den Tag ${tag.abbreviation} wirklich löschen?`} close={() => closeModal()}>
          <>
            <Grid item xs={6}>
              {button('confirmDelete')}
            </Grid>
            <Grid item xs={6}>
              {button('closeModal')}
            </Grid>
          </>
        </Modal>}
      {status.success && status.create && status.showModal &&
        <Modal title='Glückwunsch' message='Erfolgreich gespeichert' close={() => closeModal()}>
          <Grid item xs={6}>
            {button('overview')}
          </Grid>
          <Grid item xs={6}>
            {button('new')}
          </Grid>
        </Modal>}
      {status.success && status.update && status.showModal &&
        <Modal title='Glückwunsch' message='Erfolgreich editiert' close={() => closeModal()}>
          <Grid item xs={6}>
            {button('overview')}
          </Grid>
          <Grid item xs={6}>
            {button('new')}
          </Grid>
        </Modal>}
      {status.success && status.delete && status.showModal &&
        <Modal title='Glückwunsch' message='Erfolgreich gelöscht' close={() => closeModal()}>
          <Grid item xs={6}>
            {button('overview')}
          </Grid>
          <Grid item xs={6}>
            {button('new')}
          </Grid>
        </Modal>}
      <Spacer />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          {itemId !== 0 &&
            <h1>Tag bearbeiten</h1>}
          {itemId === 0 &&
            <h1>Neuen Tag anlegen</h1>}
        </Grid>
        {itemId !== 0 &&
          <Grid item xs={6}>
            <Button type='delete' onClick={() => showDelete()}>Tag löschen</Button>
          </Grid>}
      </Grid>

      <Spacer height='30px' />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <SelectionTitle title='Bitte wählen sie die Kategorie aus zudem das Tag zugewiesen werden soll.' />
            <Select options={tagCategories} value={tagCategories.find(option => option.value === tag.type)} size={4} onChange={e => handleSwitchChange(e, 'type')} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={isProductGroup ? 6 : 12}>
          <Spacer height='20px' />
          <TextInput
            title='Bitte hinterlegen Sie die Kürzel.'
            value={tag.abbreviation}
            onChange={e => handleChange(e, 'abbreviation')}
          />
          <Spacer height='20px' />
        </Grid>
        {isProductGroup &&
          <Grid item xs={6}>
            <Spacer height='20px' />
            <TextInput
              title='Kennung für Kurz-URL'
              value={tag.short_id}
              onChange={e => handleChange(e, 'short_id')}
            />
            <Spacer height='20px' />
          </Grid>}
      </Grid>

      <Grid container spacing={5}>
        {isProductGroup && (
          <Grid item xs={12}>
            <SelectionTitle>
              Hier können Sie das Foto für das Key Visual der QR Landingpage hochladen
            </SelectionTitle>
            <Dropzone
              handleDropzoneChange={handleDropzoneChange}
              target='image'
              thumbnail={tag && tag.imageUrl ? config.backend + tag.imageUrl : null}
              accept={{
                'image/jpeg': []
              }}
            />
          </Grid>)}
        <Grid item xs={12}>
          {['de', 'en', 'fr', 'it'].map((type, index) => {
            const titleType = `title_${type}`
            const descriptionType = `description_${type}`
            return (
              <Accordion key={type} defaultExpanded>
                <AccordionSummary expandIcon={<ArrowDown />}>
                  <Flag flag={type} />
                  {Translation(type)}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <TextInput
                        title='Titel'
                        value={tag[titleType]}
                        onChange={e => handleChange(e, titleType)}
                      />
                    </Grid>
                    {isProductGroup && (
                      <Grid item xs={12}>
                        <SelectionTitle>Beschreibung</SelectionTitle>
                        <Editor text={tag[descriptionType]} onChange={handleTextChange(descriptionType)} />
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Grid>
      </Grid>
      <Spacer />
      {errorNotification !== '' &&
        <>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </>}
      <Button onClick={submit}>
        {itemId !== 0 &&
          <>Tag aktualisieren</>}
        {itemId === 0 &&
          <>Tag anlegen</>}
      </Button>
      <Spacer />
    </Grid>
  )
}

export default EditForm
