import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import IconCross from '../../assets/svg/Cross.js'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import TextInput from '../common/FormElements/TextInput'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '80'
  },
  content: {
    top: '15%',
    zIndex: '90'
  }
}

const StyledModal = styled(Modal)`
  width: 960px;
  height: 170px;
  margin: 0 auto;
  background: #FFF;
  border: 1px solid black;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
`

const ClosingButton = styled.button`
  border: 0;
  background-color: transparent;
  padding-left: 143px;
`

const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const StyledTextfield = styled(TextInput)`
  width: 960px;
`

const NotificationModal = ({ boxtitle = '', type = 'success', title = '', message = '', close, children, value, handleChange }) => {
  Modal.setAppElement('#root')
  return (
    <StyledModal
      onRequestClose={close}
      shouldCloseOnOverlayClick
      isOpen
      style={customStyles}
    >
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <StyledTitle>{title}</StyledTitle>
        </Grid>
        <Grid item xs={2}>
          <ClosingButton onClick={close}>
            <IconCross height='18px' width='18px' fill='#A8ADB0' />
          </ClosingButton>
        </Grid>
        <Grid item xs={12}>
          <StyledTextfield
            title={boxtitle}
            onChange={handleChange}
            value={value}
          />
          <Spacer heigh='15px' />
          <Grid container spacing={2}>
            {children}
          </Grid>
          <Spacer height='10px' />
        </Grid>
      </Grid>
    </StyledModal>
  )
}

NotificationModal.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.any,
  children: PropTypes.any
}

export default NotificationModal
