const Component = (documents = []) => {
  const docarts = { all: { label: 'Filter zurücksetzen', value: '' } }
  const productgroups = { all: { label: 'Filter zurücksetzen', value: '' } }
  const languages = { all: { label: 'Filter zurücksetzen', value: '' } }
  const draft = { all: { label: 'Filter zurücksetzen', value: '' } }
  const brands = { all: { label: 'Filter zurücksetzen', value: '' } }
  let documentDocarts = []
  let documentProductgroups = []
  let documentLanguages = []
  let documentBrands = []
  documents.forEach(document => {
    documentDocarts = []
    documentProductgroups = []
    documentLanguages = []
    documentBrands = []
    /* Add languages filter */
    if (document.title_de) {
      documentLanguages.push('de')
      languages.de = { label: 'Deutsch', value: 'de' }
    } if (document.title_en) {
      documentLanguages.push('en')
      languages.en = { label: 'Englisch', value: 'en' }
    } if (document.title_fr) {
      documentLanguages.push('fr')
      languages.fr = { label: 'Französisch', value: 'fr' }
    } if (document.title_it) {
      documentLanguages.push('it')
      languages.it = { label: 'Italienisch', value: 'it' }
    }
    /* Add status/draft filter */
    if (document.draft) {
      draft.draft = { label: 'Geparkt', value: true }
    } else {
      draft.noDraft = { label: 'Veröffentlicht', value: false }
    }
    (document.tags || []).forEach(tag => {
      if (tag.type === 'docarts') {
        docarts[tag.id] = { label: tag.abbreviation, value: tag.id }
        documentDocarts.push(tag.id)
      } else if (tag.type === 'productgroup') {
        productgroups[tag.id] = { label: tag.abbreviation, value: tag.id }
        documentProductgroups.push(tag.id)
      }
      if (tag.type === 'brands') {
        brands[tag.id] = { label: tag.abbreviation, value: tag.id }
        documentBrands.push(tag.id)
      }
    })
    document.productgroups = documentProductgroups
    document.docarts = documentDocarts
    document.languages = documentLanguages
    document.brands = documentBrands
  })

  return {
    docarts: Object.values(docarts).sort(),
    productgroups: Object.values(productgroups).sort(),
    languages: Object.values(languages).sort(),
    draft: Object.values(draft).sort(),
    brands: Object.values(brands).sort()
  }
}

export default Component
