import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Permissions from '../common/ApiHandler/Permission'
import Checkbox from '../common/FormElements/Checkbox'
import LoadIndicator from '../common/LoadIndicator'

const Wrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 20px 20px 0px 20px;
`

const PermissionItem = ({ permission, onChange, selectedPermissions = [] }) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const checked = selectedPermissions.find(search => search === permission.id) !== undefined
    setIsChecked(checked)
    // eslint-disable-next-line
  }, [selectedPermissions])

  return (
    <Checkbox onChange={() => { onChange(permission.id) }} title={permission.title} value={permission.id} isChecked={isChecked} />
  )
}

const PermissionSelection = ({ defaultPermissions, onChange }) => {
  /* List of all permissions */
  const [permissions, setPermissions] = useState([])
  /* List of selected (checked) TAGS */
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      const API = Permissions({})
      const allPermissions = await API.search()
      setPermissions(allPermissions)
      setLoading(false)
    }
    loadData()
  }, [])

  useEffect(() => {
    setSelectedPermissions(defaultPermissions)
  }, [defaultPermissions])

  return (
    <div>
      {!loading &&
        <Wrapper>
          {permissions.map((permission) => {
            return (
              <PermissionItem key={permission.id} permission={permission} onChange={onChange} selectedPermissions={selectedPermissions} />
            )
          })}
        </Wrapper>}
      {loading &&
        <LoadIndicator />}
    </div>
  )
}

export default PermissionSelection
