const SessionStore = prefix => {
  const set = async (params) => {
    window.localStorage.setItem(prefix + '-session-token', params)
    return true
  }
  const get = () => {
    const res = window.localStorage.getItem(prefix + '-session-token')
    return res
  }
  const destroy = () => {
    window.localStorage.removeItem(prefix + '-session-token')
    return true
  }

  return {
    prefix,
    set,
    get,
    destroy
  }
}

export default SessionStore
