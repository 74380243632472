import React, { useEffect, useState, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import LinkButton from '../common/LinkButton'
import DocumentOverview from './OverviewList'
import FilterBar from './FilterBar'
import CircularProgress from '../common/LoadIndicator'
import filterDocuments from './filterDocuments'
import createPersistedState from 'use-persisted-state'
import { Documents } from '../common/ApiHandler/Document'

const Overview = () => {
  const [loading, setLoading] = useState(true)
  const [documents, setDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const useFilter = createPersistedState('filter')
  const [filter, setFilter] = useFilter({})

  const refreshDocuments = useCallback(async () => {
    setLoading(true)
    const API = Documents({})

    try {
      const fetchedDocuments = await API.findAll({})
      setDocuments(fetchedDocuments)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }, [setLoading, setDocuments])

  useEffect(() => {
    refreshDocuments()
  }, [refreshDocuments])

  const handleFilterChange = useCallback(async (newFilter) => {
    if (newFilter !== filter) {
      setFilter(newFilter)
    }
  }, [filter, setFilter])

  const handlePublish = async (document) => {
    const updatedDocument = await document.save({ draft: false, publishDate: new Date() })
    document = updatedDocument
    updateDocuments(updatedDocument)
  }
  const handleUnpublish = async (document) => {
    const updatedDocument = await document.save({ draft: true })
    updateDocuments(updatedDocument)
  }
  const handleDestroy = async (document) => {
    await document.destroy()
    updateDocuments({ ...document, _removed: true })
  }

  const updateDocuments = updatedDocument => {
    setDocuments((prevDocuments) => {
      return prevDocuments
        .map(d => d.id === updatedDocument.id ? updatedDocument : d)
        .filter(d => !d._removed)
    })
  }

  useEffect(() => {
    const filteredDocuments = filterDocuments({ documents, filter })
    setFilteredDocuments(filteredDocuments)
  }, [documents, filter])

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Spacer />
              <h1>Alle Dokumente</h1>
            </Grid>
            <Grid item xs={6}>
              <Spacer />
              <LinkButton title='Neues Dokument anlegen' icon='doc' to='/documents/create' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FilterBar onChange={handleFilterChange} documents={documents} filter={filter} />
      {loading &&
        <>
          <Spacer />
          <CircularProgress />
        </>}
      {!loading && <DocumentOverview documents={filteredDocuments} onChange={refreshDocuments} onPublish={handlePublish} onUnpublish={handleUnpublish} onDestroy={handleDestroy} />}
    </Grid>
  )
}

export default Overview
