import Api from './Api'

const api = Api()

export const User = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    save: async function () {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        return res.error
      }
      return User(res)
    },
    destroy: async () => {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'DELETE'
        })
      if (res.error) {
        return res.error
      }
      return res
    }
  }
}

export const Users = ({ id, ...restProps }) => {
  return {
    id,
    ...restProps,
    findAll: async (params) => {
      const res = await api.exec('/users', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        return res.error
      }
      if (res.users.length < 1) {
        return []
      }
      return res.users.map(user => User(user))
    },
    findOne: async () => {
      const res = await api.exec(`/users/${id}`, {
        method: 'GET'
      })
      if (res.error) {
        return res.error
      }
      return User(res.user)
    }
  }
}

export default Users
