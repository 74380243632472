import React from 'react'

const SvgX = props => (
  <svg viewBox='0 0 14 14' {...props}>
    <defs>
      <path
        id='x_svg__a'
        d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#x_svg__a'
      transform='translate(-5 -5)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgX
