import React from 'react'

const SvgDownload = props => (
  <svg viewBox='0 0 14 17' {...props}>
    <defs>
      <path
        d='M491 399h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z'
        id='download_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#download_svg__a'
      transform='translate(-477 -393)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgDownload
