import { useEffect, useState, useCallback } from 'react'
import Uploady, { useUploady, useChunkStartListener, UPLOADER_EVENTS } from '@rpldy/chunked-uploady'
import { v1 as uuidv1 } from 'uuid'
import Api from './Api'
import Session from './Session'

const api = Api()

export const UploaderContext = Uploady

export const useChunkedUpload = (debug = false) => {
  const { upload, on, off, clearPending, abort } = useUploady()

  const [uploadProgress, setUploadProgress] = useState(0)

  // Uploader event logger
  useEffect(() => {
    if (!debug) return
    const eventsToListenTo = [
      UPLOADER_EVENTS.ITEM_ADD,
      UPLOADER_EVENTS.ITEM_START,
      UPLOADER_EVENTS.ITEM_PROGRESS,
      UPLOADER_EVENTS.ITEM_FINISH,
      UPLOADER_EVENTS.ITEM_ABORT,
      UPLOADER_EVENTS.ITEM_ERROR
    ]

    const onEvent = (event) => (data) => console.log(event, data)

    const listeners = Object.fromEntries(eventsToListenTo.map(event => [event, onEvent(event)]))

    eventsToListenTo.forEach(event => on(event, listeners[event]))
    return () => {
      eventsToListenTo.forEach(event => off(event, listeners[event]))
    }
  }, [on, off, debug])

  useChunkStartListener(({ url, chunk}) => ({ url: `${url}/${chunk.index}` }))

  const uploadItem = useCallback(async ({ documentId, file }) => {
    return new Promise((resolve, reject) => {
      // remove previously enqueued upload items
      clearPending()

      // register event listeners
      const onProgress = (data) => {
        setUploadProgress(data.completed)
      }
      on(UPLOADER_EVENTS.ITEM_PROGRESS, onProgress)
      const onFinished = (data) => {
        cleanup()
        resolve(data)
      }
      on(UPLOADER_EVENTS.ITEM_FINISH, onFinished)
      const onError = (data) => {
        cleanup()
        reject(data)
      }
      on(UPLOADER_EVENTS.ITEM_ERROR, onError)
      const onAbort = (data) => {
        cleanup()
        reject(data)
      }
      on(UPLOADER_EVENTS.ITEM_ABORT, onAbort)

      const cleanup = () => {
        off(UPLOADER_EVENTS.ITEM_PROGRESS, onProgress)
        off(UPLOADER_EVENTS.ITEM_FINISH, onFinished)
        off(UPLOADER_EVENTS.ITEM_ERROR, onError)
        off(UPLOADER_EVENTS.ITEM_ABORT, onAbort)
        setUploadProgress(0)
      }

      const fileId = uuidv1()

      const uploadOptions = {
        destination: {
          url: api.buildUrl(`/documents/${documentId}/upload/false/${fileId}`),
          headers: {}
        }
      }
      // add session header
      const session = Session()
      session.append(uploadOptions.destination)
      // init file upload
      upload(file, uploadOptions)
    })
  }, [clearPending, on, off, upload])

  return {
    upload: uploadItem,
    abort,
    uploadProgress,
    _useLibrary: useUploady
  }
}
