import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextInput from '../common/FormElements/TextInput'
import Spacer from '../common/Spacer'
import Switch from '../common/FormElements/Switch'
import Notification from '../common/GeneralNotification'

const EditFormFields = ({ user, setUser, children, errorNotification, create, ...restProps }) => {
  const handleSelectChange = (event, field) => {
    setUser({
      ...user,
      [field]: event.target.checked
    })
  }

  const languageTranslation = {
    de: 'Deutsch',
    it: 'Italienisch',
    en: 'Englisch',
    fr: 'Französisch'
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextInput
          title='Kundennummer'
          readOnly
          value={user.customer_no || user.user_metadata.customerId}
        />
        <Spacer height='20px' />
        <TextInput
          title='Vorname'
          readOnly
          value={user.firstname}
        />
        <Spacer height='20px' />
        <TextInput
          title='E-Mail-Adresse'
          readOnly
          value={user.email}
        />
        <Spacer height='20px' />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          title='Firma'
          readOnly
          value={user.company}
        />
        <Spacer height='20px' />
        <TextInput
          title='Nachname'
          readOnly
          value={user.lastname}
        />
        <Spacer height='20px' />
        <TextInput
          title='Sprache'
          readOnly
          value={languageTranslation[user.language]}
        />
      </Grid>
      <Grid item xs={12}>
        <h3>Einstellungen zum Händler</h3>
      </Grid>
      <Grid item xs={6}>
        <Switch
          label='eLearning'
          onChange={e => handleSelectChange(e, 'ilias')}
          checked={user.ilias}
        />
      </Grid>
      <Grid item xs={12}>
        {errorNotification !== '' &&
          <>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </>}
        {children}
        <Spacer />
      </Grid>
    </Grid>
  )
}

EditFormFields.propTypes = {
  user: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any,
  setUser: PropTypes.any,
  errorNotification: PropTypes.string
}

export default EditFormFields
