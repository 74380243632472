import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import Dropzone from '../common/FormElements/Dropzone'
import styled from 'styled-components'
import Button from '../common/Button'
import Modal from '../common/NotificationModal'
import Documents, { Document } from '../common/ApiHandler/Document'
import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import TagSelection from '../Tag/TagSelection'
import { Permission } from '../common/ApiHandler/Permission'
import PermissionSelection from '../Permissions/PermissionSelection'
import MarkAsNew from './markAsNew'
import LoadIndicator from '../common/LoadIndicator'
import Switch from '../common/FormElements/Switch'
import TextInput from '../common/FormElements/TextInput'
import Flag from '../common/Flag'
import { UploaderContext, useChunkedUpload } from '../common/ApiHandler/ChunkedUpload'

// break into 20 MB chunks for demo purposes
const chunkSize = 20 * 1_000_000

const isSupportedForPreviewGeneration = (type) => {
  if (!type) {
    return false
  }
  if (type.includes('image') || type.includes('pdf')) {
    return true
  }
  return false
}

const DocumentEdit = (props) => {
  return (
    <UploaderContext
      multiple={false}
      chunkSize={chunkSize}
      clearPendingOnAdd
    >
      <DocumentEditForm {...props} />
    </UploaderContext>
  )
}

const LeavePageNotificationListener = {
  onBeforeUnload: event => {
    event.preventDefault()
  },
  unregisterBlockBackFn: null,
  blockBack: function (history) {
    let unblock = history.block(() => {
      const shouldUnblock = window.confirm(Translation('This page is asking you to confirm that you want to leave — information you\'ve entered may not be saved.'))
      if (shouldUnblock) {
        this.unregisterBlockBackFn = null
        unblock()
        return true
      }
      return false
    })
    this.unregisterBlockBackFn = unblock
    return unblock
  },
  add: function (history) {
    window.addEventListener('beforeunload', this.onBeforeUnload)
    this.blockBack(history)
  },
  remove: function (history) {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
    if (this.unregisterBlockBackFn) {
      this.unregisterBlockBackFn()
    }
  }
}

const DocumentEditForm = ({ match, history, ...props }) => {
  const initialState = {
    id: '',
    youtube: '',
    title_en: '',
    title_de: '',
    title_fr: '',
    title_it: '',
    shareable: false,
    showAsNew: false,
    channelqrweb: false,
    availableForOrder: false,
    draft: true,
    tags: [],
    permissions: [],
    thumbnail: '',
    file: '',
    newSince: undefined
  }

  const [document, setDocument] = useState(initialState)

  const [errorNotification, setErrorNotification] = useState('')

  /* Default: Create Mode */
  const [status, setStatus] = useState({
    create: true,
    update: false,
    success: false
  })

  const [oldPermissions, setOldPermissions] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [oldTags, setOldTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [loading, setLoading] = useState(false)

  const [thumbnail, setThumbnail] = useState('')
  const [display, setDisplay] = useState({
    file: false,
    thumbnail: false
  })

  const { upload, abort, uploadProgress } = useChunkedUpload()

  /* Switch to update mode and receive data */
  useEffect(() => {
    if (match.params.id !== undefined) {
      setStatus(prevStatus => ({ ...prevStatus, update: true, create: false }))
      loadData()
    } else {
      // Create Mode, reset everything to initial State
      setStatus(prevStatus => ({ ...prevStatus, create: true, update: false, delete: false, success: false }))
      setDocument(initialState)
      setOldTags([])
      setSelectedTags([])
      setOldPermissions([])
      setSelectedPermissions([])
    }
    // eslint-disable-next-line
  }, [match])

  useEffect(() => {
    if (document.filename) {
      setDisplay(prevDisplay => ({ ...prevDisplay, file: true }))
    }
    // eslint-disable-next-line
  }, [document.filename])

  useEffect(() => {
    // Remove scrolling from document Overview and go to top of page
    const scrollTop = () => {
      const header = window.document.getElementById('#header')
      if (header) {
        header.scrollIntoView()
      }
    }
    scrollTop()
  }, [])

  const getThumbnail = async (foundDocument) => {
    const thumbnail = await Document(foundDocument).getThumbnailUrl()
    await setThumbnail(thumbnail)
    if (foundDocument.filename) {
      setDisplay({ file: true, thumbnail: true })
    } else {
      setDisplay(prevDisplay => ({ ...prevDisplay, thumbnail: true }))
    }
  }
  /**
   * INITIAL DATALOAD
   */
  /* Load Data for Update */
  const loadData = async () => {
    const currentDocument = Documents({ id: match.params.id })
    const foundDocument = await currentDocument.findOne()

    const allTagData = foundDocument.tags
    const originalTags = []
    allTagData.forEach((tag) => {
      originalTags.push(tag.id)
    })
    setTags(originalTags)
    setDocument(foundDocument)
    getThumbnail(foundDocument)

    const originalPermissions = []
    const allPermissionData = foundDocument.permissions
    allPermissionData.forEach((permission) => {
      originalPermissions.push(permission.id)
    })
    setOldPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
    setStatus(prevStatus => ({ ...prevStatus, documentReady: true }))
  }

  const createNewDocument = async (documentData) => {
    const API = Documents({ ...documentData, file: null })
    const response = await API.create()
    if (typeof response === 'string') {
      throw new Error(response)
    }
    return response
  }

  const updateDocument = async (documentInstance) => {
    const response = await documentInstance.save()
    if (typeof response === 'string') {
      throw new Error(response)
    }
    return response
  }

  const uploadFile = async (documentInstance) => {
    if (document.file && typeof document.file !== 'string') {
      return upload({
        documentId: documentInstance.id,
        file: document.file
      })
    }
    return null
  }

  const uploadThumbnail = async (documentInstance) => {
    if (typeof document.thumbnail !== 'string' && document.thumbnail !== undefined) { // @TODO on create make no request for thumbnail === undefined
      const DocAPI = Documents(document)
      const response = DocAPI.upload(document.thumbnail, true, documentInstance.id)
      if (typeof response === 'string') {
        throw new Error(response)
      }
    }
    return null
  }

  const updateAssignments = async (documentInstance) => {
    getUnassignedTags().forEach(async (tagId) => {
      await documentInstance.unassignTag(tagId)
    })
    getAssignedTags().forEach(async (tagId) => {
      await documentInstance.assignTag(tagId)
    })
    /* handle Permissions */
    const API = Permission({})
    getUnassignedPermissions().forEach(async (permissionId) => {
      await API.unassignDocument(documentInstance.id, permissionId)
    })
    getAssignedPermissions().forEach(async (permissionId) => {
      await API.assignDocument(documentInstance.id, permissionId)
    })
  }

  /**
   * DATA SUBMIT
   */
  const submit = async () => {
    setLoading(true)
    setErrorNotification('')
    LeavePageNotificationListener.add(history)

    const documentWillBeCreated = !document.id
    const documentWillBeUpdated = document.id

    let documentInstance = null
    try {
      if (documentWillBeCreated) {
        documentInstance = await createNewDocument(document)
      }
      if (documentWillBeUpdated) {
        const updatedDocument = { ...document }
        delete updatedDocument.referenceid
        documentInstance = Document({ ...updatedDocument })
      }

      await uploadFile(documentInstance)
      await uploadThumbnail(documentInstance)
      if (documentWillBeUpdated) {
        await updateDocument(documentInstance)
      }
      await updateAssignments(documentInstance)

      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } catch (error) {
      if (documentWillBeCreated) {
        // remove temporarily created document
        try {
          await documentInstance?.destroy()
        } catch (error) {
          console.error('inital document created could not be removed', error)
        }
      }
      if (error?.state !== 'aborted') {
        console.error(`document ${documentWillBeCreated ? 'create' : 'update'} error`, error)
        setErrorNotification(Translation(error?.message || error?.uploadResponse?.reason || error))
      }
    }

    LeavePageNotificationListener.remove(history)
    setLoading(false)
  }

  /**
   * TAG & PERMISSION HANDLING
   */

  /* Assign/Unassign Tags */
  const setTags = (tags) => {
    setOldTags(tags)
    setSelectedTags(tags)
  }

  const getUnassignedTags = () => {
    return oldTags.filter(id => !selectedTags.includes(id))
  }

  const getAssignedTags = () => {
    return selectedTags.filter(id => !oldTags.includes(id))
  }

  const getUnassignedPermissions = () => {
    return oldPermissions.filter(id => !selectedPermissions.includes(id))
  }

  const getAssignedPermissions = () => {
    return selectedPermissions.filter(id => !oldPermissions.includes(id))
  }

  /* Updates the tags to current selection */
  const handleTagChange = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(stag => stag !== tag))
    } else {
      setSelectedTags(prevSelectedTags => [...prevSelectedTags, tag])
    }
  }

  const handlePermissionChange = async (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions(prevSelectedPermissions => [...prevSelectedPermissions, permission])
    }
  }

  /* Modals & Notifications */
  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, success: false }))
  }

  const resetState = () => {
    setStatus({ create: true, success: false })
    window.location.reload()
  }

  /**
   * EVENT HANDLERS
   */

  const handleDropzoneChange = async (field, acceptedFiles) => {
    try {
      const file = acceptedFiles[0]
      setDocument(prevDocument => ({
        ...prevDocument,
        [field]: file
      }))
    } catch (err) {
      console.error(err)
    }
    // check which field is filled to remove preview
    if (field === 'file') {
      setDisplay(prevDisplay => ({ ...prevDisplay, file: false }))
    } else {
      setDisplay(prevDisplay => ({ ...prevDisplay, thumbnail: false }))
      setThumbnail('')
    }
  }

  const handleSwitchChange = (event, field) => {
    setDocument(prevDocument => ({
      ...prevDocument,
      [field]: event.target.checked
    }))
  }

  const handleChange = (event, field) => {
    setDocument(prevDocument => ({
      ...prevDocument,
      [field]: event.target.value
    }))
  }

  const showPreviewWarning = (document.file && !document.thumbnail && !thumbnail && !document.filePreviewGenerated && !document.filePreviewPreviewGenerated && !isSupportedForPreviewGeneration(document.file.type || document.file.mimetype))
  const previewWarningText = 'Bitte laden Sie für dieses Dokument manuell ein Vorschaubild hoch. Solange Sie kein Vorschaubild manuell hochladen, wird ein generisches Vorschaubild angezeigt.'
  return (
    <Grid>
      {status.success && status.create &&
        <Modal title='Glückwunsch' message='Erfolgreich gespeichert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/documents'>Zu den Entwürfen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/documents/create' onClick={() => resetState()}>Weitere Dokumente hochladen</Button>
          </Grid>
        </Modal>}
      {status.success && status.update &&
        <Modal title='Glückwunsch' message='Erfolgreich editiert' close={e => closeModal()}>
          <Grid item xs={6}>
            <Button to='/documents'>Zu den Entwürfen</Button>
          </Grid>
          <Grid item xs={6}>
            <Button to='/documents/create'>Neue Dokumente hochladen</Button>
          </Grid>
        </Modal>}
      {loading &&
        <Modal type='upload' title='Dokument wird hochgeladen' message='Einen Moment bitte...'>
          <LoadIndicator progress={uploadProgress} />
          <Button type='cancel' style={{ minWidth: '100%' }} onClick={() => abort()}>Cancel</Button>
        </Modal>}
      <Spacer height='40px' />
      {status.create &&
        <h1>Neues Dokument anlegen</h1>}
      {status.update &&
        <h1>Dokument bearbeiten</h1>}
      <Spacer height='30px' />
      <Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <p>
              Hier können Sie das Dokument auf die Fläche droppen lassen oder per Klick auf die
              Fläche vom Computer auswählen
            </p>
            <Spacer height='15px' />
            <Dropzone handleDropzoneChange={handleDropzoneChange} target='file' />
            {display.file &&
              <StyledFilenameBox>
                <FileHeader>Hochgeladene Datei</FileHeader>
                <Filename>{document.filename}</Filename>
              </StyledFilenameBox>}
          </Grid>
          <Grid item xs={6}>
            <p>
              Cover-Bild für das Dokument, hier droppen lassen oder per Klick auf die Fläche Bild
              vom Computer auswählen
            </p>
            <Spacer height='15px' />
            <Dropzone
              handleDropzoneChange={handleDropzoneChange}
              target='thumbnail'
              icon='image'
              thumbnail={thumbnail}
              warningText={showPreviewWarning ? previewWarningText : ''}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TextInput
              bold
              title='Youtube-ID:'
              value={document.youtube || ''}
              isValid
              onChange={e => handleChange(e, 'youtube')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='30px' />
            <h3>Titel für Dokument vergeben (max. 35 Zeichen)</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Flag flag='de' />
            <TextInput
              title='Deutschen Titel hinzufügen:'
              value={document.title_de || ''}
              onChange={e => handleChange(e, 'title_de')}
            />
          </Grid>
          <Grid item xs={6}>
            <Flag flag='en' />
            <TextInput
              title='Englischen Titel hinzufügen:'
              value={document.title_en || ''}
              onChange={e => handleChange(e, 'title_en')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Flag flag='fr' />
            <TextInput
              title='Französischen Titel hinzufügen:'
              value={document.title_fr || ''}
              isValid
              onChange={e => handleChange(e, 'title_fr')}
            />
          </Grid>
          <Grid item xs={6}>
            <Flag flag='it' />
            <TextInput
              title='Italienischen Titel hinzufügen:'
              isValid
              value={document.title_it || ''}
              onChange={e => handleChange(e, 'title_it')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Spacer height='20px' />
            <h3>Einstellungen zur Datei</h3>
            <Spacer height='20px' />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Switch
              onChange={e => handleSwitchChange(e, 'availableForOrder')}
              checked={document.availableForOrder}
              label='Bestellbar'
            />
          </Grid>
          <Grid item xs={6}>
            <Switch
              onChange={e => handleSwitchChange(e, 'shareable')}
              checked={document.shareable}
              label='Teilen aktivieren'
            />
            <Switch
              onChange={e => handleSwitchChange(e, 'channelqrweb')}
              checked={document.channelqrweb}
              label='Sichtbar für Productcode'
            />

          </Grid>
        </Grid>
        <Spacer />
        <h3>Neu Markierung der Datei</h3>
        <Spacer height='20px' />
        <Grid container>
          <Grid item xs={6}>
            <MarkAsNew document={document} setDocument={setDocument} />
          </Grid>
        </Grid>

        <Spacer />
        <h3>Tag Zuweisung</h3>
        <Spacer height='20px' />
        <TagSelection onChange={handleTagChange} defaultTags={selectedTags} />
        <Spacer />
        <h3>Berechtigungen</h3>
        <Spacer height='20px' />
        <PermissionSelection defaultPermissions={selectedPermissions} onChange={handlePermissionChange} />
        <Spacer height='20px' />
        {errorNotification !== '' &&
          <div>
            <Notification type='error' message={errorNotification} />
            <Spacer />
          </div>}
        <Button onClick={e => submit()}>
          {status.create &&
            <>Dokument erstellen</>}
          {status.update &&
            <>Dokument aktualisieren</>}
        </Button>
        <Spacer />
      </Grid>
    </Grid>
  )
}

const StyledFilenameBox = styled.div`
  background-color: rgba(0,0,0,0.3);
  min-height: 48px;
  padding: 10px;
`

const FileHeader = styled.h4`
  position: relative;
  text-align:center;
`

const Filename = styled.p`
position: relative;
  text-align:center;
  padding-top: 5px
`

export default DocumentEdit
