import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledLabel = styled.label`
  display: box;
`

const OneLineLabel = styled.label`
  line-height: 40px;
`

function SelectionTitle (props) {
  const title = props.title || props.children
  return (
    <>
      {!props.oneLine &&
        <StyledLabel>{title}</StyledLabel>}
      {props.oneLine &&
        <OneLineLabel>{title}</OneLineLabel>}
    </>
  )
}

SelectionTitle.propTypes = {
  title: PropTypes.string
}

export default SelectionTitle
