import React from 'react'
import TextInput from '../../common/FormElements/TextInput'
import ResetButton from '../../common/FormElements/SearchButton'
import SearchWrapper from '../../common/FormElements/SearchWrapper'

const Component = ({ title: value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const resetValue = () => {
    onChange('')
  }

  return (
    <SearchWrapper>
      <TextInput onChange={handleChange} value={value} placeholder='Suche...' />
      {value && <ResetButton active onClick={resetValue} />}
    </SearchWrapper>
  )
}

export default Component
