import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import IconPlus from '../../assets/svg/Plus'
import Modal from './NotificationModal'
import Button from '../common/Button'

import Notification from '../common/GeneralNotification'
import Translation from '../common/BasicTranslation'
import Spacer from '../common/Spacer'
import PermissionItem from './PermissionItem'
import Permissions from '../common/ApiHandler/Permission'
import LoadIndicator from '../common/LoadIndicator'

const BlueText = styled.button`
  color: #50B4C8;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  margin-top: -28px;
  padding-left: 23px;
  background-color: transparent;
`

const StyledAbort = styled(Button)`
  margin-top: -10px;
  background-color: #A8ADB0;
  height: 40px;
  width: 460px;
`

const StyledConfirm = styled(Button)`
  margin-top: -10px;
  margin-left: 12px;
  height: 40px;
  width: 460px;
`

const Overview = ({ comment }) => {
  const [permissions, setPermissions] = React.useState([])
  const [errorNotification, setErrorNotification] = React.useState('')
  const [status, setStatus] = React.useState({
    create: false
  })

  const [permission, setPermission] = React.useState({
    title: ''
  })
  const [loading, setLoading] = React.useState(false)

  const reload = async () => {
    setLoading(true)
    const API = Permissions({})
    const allPermissions = await API.search()
    setPermissions(allPermissions)
    if (typeof allPermissions !== 'object') {
      setErrorNotification(Translation(allPermissions))
    }
    setPermissions(allPermissions)
    setLoading(false)
  }

  React.useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [])

  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, create: false }))
  }

  const submit = async () => {
    let res = 'false'
    const API = Permissions(permission)
    res = await API.create(permission)
    if (typeof res === 'object') {
      // Permission was edited, update tags
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
    closeModal()
    window.location.reload()
  }

  const addPermission = () => {
    setStatus(prevStatus => ({ ...prevStatus, create: true }))
  }

  const handleChange = (event, field) => {
    setPermission({
      ...permission,
      [field]: event.target.value
    })
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Spacer />
        <h1>Berechtigung verwalten</h1>
        <Spacer />
      </Grid>
      {status.create &&
        <Modal title='Neue Berechtigung erstellen' close={e => closeModal()} boxtitle='Berechtigungstitel' handleChange={e => handleChange(e, 'title')} value={permission.title}>
          <Grid item xs={6}>
            <StyledAbort onClick={() => closeModal()}>Abbrechen</StyledAbort>
          </Grid>
          <Grid item xs={6}>
            <StyledConfirm onClick={() => submit()}>Erstellen</StyledConfirm>
          </Grid>
        </Modal>}
      <Grid item xs={8}>
        <IconPlus onClick={e => addPermission()} height='40px' width='40px' fill='#22a2d1' />
        <BlueText onClick={e => addPermission()}>Berechtigung hinzufügen</BlueText>
        {
          Array.isArray(permissions) && permissions.length > 0 && permissions.map((permission) => {
            return (
              <PermissionItem key={permission.id} item={permission} />
            )
          })
        }
      </Grid>
      <Spacer />
      {loading &&
        <LoadIndicator />}
      {errorNotification !== '' &&
        <div>
          <Notification type='error' message={errorNotification} />
          <Spacer />
        </div>}
    </Grid>
  )
}

export default Overview
