import React from 'react'

const SvgPlus = props => (
  <svg viewBox='0 0 40 40' width='1em' height='1em' {...props}>
    <title>plus</title>
    <path
      d='M21 21v6h-2v-6h-6v-2h6v-6h2v6h6v2h-6zM0 0h40v40H0V0zm1 1v38h38V1H1z'
      fill='#22a2d1'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgPlus
