import React from 'react'

const SvgUpload = props => (
  <svg viewBox='0 0 14 17' {...props}>
    <defs>
      <path d='M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z' id='upload_svg__a' />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#upload_svg__a'
      transform='translate(-5 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgUpload
