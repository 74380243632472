const Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'AWLMHyNI6iIBePtIYJO2OziSKPJk9vGZ',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'auth.asab.hpm.agency',
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL || window.location.origin,
  scope: process.env.REACT_APP_AUTH0_SCOPE || 'openid profile email',
  responseType: process.env.REACT_APP_AUTH0_SCOPE || 'token',
  grant_type: process.env.REACT_APP_AUTH0_GRANT_TYPE || 'authorization_code'
}

export default Config
