import React from 'react'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import LinkButton from '../common/LinkButton'
import { Wrapper, TabSelection, TabButton, Tab, TabContent, StyledLink, SpanUnderline, loadTags, splitToChunks } from './TagBase'
import Translation from '../common/BasicTranslation'
import LoadIndicator from '../common/LoadIndicator'

const Overview = () => {
  const [activeTab, setActiveTab] = React.useState(0)
  const [tags, setTags] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  // const emptyArray = []

  const loadData = async () => {
    setLoading(true)
    const tagGroups = await loadTags()
    const groupKeys = Object.keys(tagGroups)
    groupKeys.forEach(group => {
      const tags = tagGroups[group]
      tagGroups[group] = tags.sort((a, b) => {
        return String(a.title_de).localeCompare(b.title_de)
      })
    })
    await setTags(tagGroups)
    setLoading(false)
  }

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Spacer />
              <h1>Alle Tags</h1>
              <Spacer />
            </Grid>
            <Grid item xs={6}>
              <Spacer />
              <LinkButton title='Neuen Tag anlegen' icon='tag' to='/tags/create' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading &&
        <Wrapper>
          <TabSelection>
            {Object.keys(tags).sort().map((type, index) => {
              return (
                <TabButton key={index} onClick={() => setActiveTab(index)} className={(activeTab === index ? 'active' : '')}>{Translation(type)}</TabButton>
              )
            })}
          </TabSelection>
          <TabContent>
            {Object.keys(tags).sort().map((type, index) => {
              const sortedTags = tags[type].sort()
              const tagChunks = splitToChunks([...sortedTags], 3)
              if (activeTab === index) {
                return (
                  <Tab key={index} active={activeTab === index}>
                    <Grid container>
                      {tagChunks.map((chunk, index) => {
                        return (
                          <Grid key={index} item xs={4}>
                            {chunk.map((tag, index) => {
                              return (
                                <StyledLink key={index} to={`/tags/update/${tag.id}`}>
                                  <SpanUnderline>
                                    {tag.abbreviation}
                                  </SpanUnderline>
                                </StyledLink>
                              )
                            })}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Tab>
                )
              } else {
                return null
              }
            })}
          </TabContent>
        </Wrapper>}
      {loading &&
        <LoadIndicator />}
    </Grid>
  )
}

export default Overview
