import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import PropTypes from 'prop-types'

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#FFF',
    '&$checked': {
      color: '#22a2d1'
    },
    '&$checked + $track': {
      backgroundColor: '#22a2d1'
    }
  },
  checked: {},
  track: {}
})(Switch)

const StyledSwitchLabel = styled.label`
  font-size: 14px;
  clear: right;
  padding-bottom: 20px;
`

const StyledSwitch = styled(PurpleSwitch)`
  float: right;
  margin-top: -10px;
`

// #22a2d1 #4283C1

const ReaSwitch = (props) => {
  return (
    <StyledSwitchLabel>
      {props.label}
      <StyledSwitch color='primary' {...props} />
    </StyledSwitchLabel>
  )
}

ReaSwitch.propTypes = {
  label: PropTypes.string
}

export default ReaSwitch
