import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Spacer from './Spacer'

const Wrapper = styled(Box)`
  min-width: 100%;
  min-height: 60px;
`

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ minWidth: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <LinearProgress variant='determinate' {...props} style={{ width: '100%' }} />
        <Spacer height='8px' />
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const LoadIndicator = ({ progress = 0, ...restProps }) => {
  return (
    <Wrapper {...restProps}>
      {(progress > 0 && progress <= 99) ? <LinearProgressWithLabel value={progress} /> : <CircularProgress sx={{ color: '#00528C' }} />}
    </Wrapper>
  )
}

export default LoadIndicator
