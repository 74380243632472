import React from 'react'
// import IconEdit from '../../../assets/svg/Bearbeiten.js'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SelectionTitle from './SelectionTitle'

const StyledInput = styled.input`
  box-sizing: border-box;
  resize: none;
  color: #30393f;
  width: 100%;
  flex: 1;
  background-color: #ffffff;
  padding: 10px;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledReadOnlyInput = styled(StyledInput)`
  background-color: #F5F5F5;
  position: relative;
`

// const StyledEditIcon = styled(IconEdit)`
//   position: absolute;
//   right: 15px;
//   top: 1px;
//   height: 40px;
// `

const TextInput = (props) => {
  return (
    <>
      {props.title !== '' &&
        <SelectionTitle title={props.title} />}
      {!props.readOnly &&
        <StyledInput {...props} />}
      {props.readOnly &&
        <StyledWrapper>
          <StyledReadOnlyInput {...props} readOnly />
          {/* <StyledEditIcon width='18' height='18' fill='' /> */}
        </StyledWrapper>}
    </>
  )
}

TextInput.defaultProps = {
  title: '',
  readOnly: false
}

TextInput.propTypes = {
  title: PropTypes.string,
  readOnly: PropTypes.any,
  value: PropTypes.string
}

export default TextInput
