import React from 'react'
import IconUpload from '../../../assets/svg/Upload'
import IconImage from '../../../assets/svg/Foto'

import Translation from '../BasicTranslation'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useDropzone } from 'react-dropzone'

import { partial } from "filesize";

const formatFilesize = partial({ locale: 'de' })

const StyledDiv = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 180px;
  border: 1px dashed #9C9C9C;
  cursor: pointer;
  background-color: #F5F5F5;
  .dropzone {
    width: 100%;
    min-height: 200px;
  }
`

const FileInformation = styled.aside`
  background: rgba(0,0,0,0.3);
  padding: 10px;
  min-height: 48px;
  ul {
    li {
      padding-top: 10px;
    }
  }
`

const FileWarning = styled.aside`
  background: #EAA625;
  padding: 10px;
  min-height: 48px;
  ul {
    li {
      padding-top: 10px;
    }
  }
`

const FileError = styled.aside`
  background: #EBCCD1;
  padding: 10px;
  min-height: 48px;
  ul {
    li {
      padding-top: 10px;
    }
  }
`

const Foreground = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  height: 100%;
`

const bgStyles = `
  position: absolute;
  height: 100px;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`

const UploadBg = styled(IconUpload)`
  ${bgStyles}
`
const ImageBg = styled(IconImage)`
  ${bgStyles}
`

const DropzoneFileInformation = (info) => {
  const file = info.errors ? info.file : info
  return (
    <li key={file.path}>
      {file.path} - {formatFilesize(file.size)}
      {info.errors && <ul>
        {info.errors.map(e => (
          <li key={e.code}>{Translation(e.message)}</li>
        ))}
      </ul>}
    </li>
  )
}

const DropZone = ({ icon = 'upload', handleDropzoneChange, target, thumbnail = '', warningText = '', accept }) => {
  /* Triggers when file is selected via dialog or dropped in */
  const onDropAccepted = (acceptedFiles) => {
    handleDropzoneChange(target, acceptedFiles)
  }
  const onDropRejected = () => {
    handleDropzoneChange(target, [])
  }

  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({ onDropAccepted, onDropRejected, accept })

  const files = acceptedFiles.map(DropzoneFileInformation)
  const rejections = fileRejections.map(DropzoneFileInformation)

  return (
    <StyledDiv>
      {icon === 'upload' &&
        <UploadBg fill='#939393' />}
      {icon === 'image' &&
        <ImageBg fill='#939393' />}
      <Foreground>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {thumbnail &&
            <ThumbnailHolder>
              <DropContainer>
                <DocImageWrapper>
                  <DocImage src={thumbnail} />
                </DocImageWrapper>
              </DropContainer>
            </ThumbnailHolder>}
        </div>
        {files.length > 0 &&
          <FileInformation>
            <h4>Ausgewählte Datei</h4>
            <ul>{files}</ul>
          </FileInformation>}
        {rejections.length > 0 &&
          <FileError>
              <h4>Ausgewählte Datei</h4>
              <ul>{rejections}</ul>
          </FileError>}
        {warningText &&
          <FileWarning>
            {warningText}
          </FileWarning>}
      </Foreground>
    </StyledDiv>
  )
}

DropZone.propTypes = {
  icon: PropTypes.string,
  filetype: PropTypes.string
}

export default DropZone

const DocImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 2;
`

const DocImage = styled.img`
  max-width: 100%;
  max-height: 150px;
  margin-top: 25px;
  z-index: 2;
`

const ThumbnailHolder = styled.div`
  position:relative
`

const DropContainer = styled.div`
  position: relative;
  z-index: 1
`
