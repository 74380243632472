import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import Header from './common/Header'
import Footer from './common/Footer'
import Grid from '@material-ui/core/Grid'
import NavBar from './common/NavBar'
import { useSession } from './common/ApiHandler/Session'
import Config from './auth0Config'

import '../assets/reset.css'
import '../assets/main.css'

import Dashboard from './Dashboard/Dashboard'
import UserOverview from './User/Overview'
import UserUpdate from './User/Update'
import DocumentOverview from './Document/Overview'
import DocumentEdit from './Document/Edit'
import TagOverview from './Tag/Overview'
import TagEdit from './Tag/EditForm'
import styled from 'styled-components'
import Login from './Login/Login'
import Permissions from './Permissions/Overview'
import PermissionEdit from './Permissions/Edit'
import PermissionGroupOverview from './PermissionGroups/Overview'
import PermissionGroupEdit from './PermissionGroups/Edit'
import { Auth0Provider } from '@auth0/auth0-react'

/**
 * CSP: Set a special variable to add `nonce` attributes to all styles/script tags.
 * The value is set in index.html and generated in nginx on request.
 * See https://github.com/webpack/webpack/pull/3210
 */
/* global __webpack_nonce__ */ // eslint-disable-line no-unused-vars
// webpack, styled-components, google tag manager
__webpack_nonce__ = window.__webpack_nonce__ // eslint-disable-line no-global-assign, camelcase
// material-ui styles through emotion
const cache = createCache({
  key: 'css',
  nonce: window.__webpack_nonce__
})
const EmotionStyleCacheContainer = ({ children, ...restProps }) => {
  return (
    <CacheProvider value={cache}>
      {children}
    </CacheProvider>
  )
}

const StyledApp = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const LoginBackground = styled(Grid)`
  background: linear-gradient(180deg, #D3D6D8 0%, #A8ADB0 100%);
  margin-top: 100px;
`

const FixedGrid = styled(Grid)`
  flex: 1;
  justify-content: center;
  max-width: 1440px;
`

const FixedGridLogin = styled(Grid)`
  flex: 1;
  justify-content: center;
  max-width: 100vw;
`

function App () {
  const { session } = useSession()

  if (!session || window.localStorage.getItem('asab-session-token') === 'LOGOUT') {
    return (
      <EmotionStyleCacheContainer>
        <Auth0Provider
          domain={Config.domain}
          clientId={Config.clientId}
          redirectUri={Config.redirectUri}
          scope={Config.scope}
          responseType={Config.responseType}
          grant_type={Config.grant_type}
        >
          <Router basename={window.location.pathname + '#'}>
            <StyledApp>
              <Header />
              <FixedGridLogin container>
                <LoginBackground item xs={12}>
                  <Switch>
                    <Route path='/login' exact component={Login} />
                    <Route path='/' exact component={Login} />
                    <Route render={() => (
                      <Redirect to='/' />
                    )}
                    />
                  </Switch>
                </LoginBackground>
              </FixedGridLogin>
              <Footer />
            </StyledApp>
          </Router>
        </Auth0Provider>
      </EmotionStyleCacheContainer>
    )
  }

  return (
    <EmotionStyleCacheContainer>
      <Auth0Provider
        domain={Config.domain}
        clientId={Config.clientId}
        redirectUri={Config.redirectUri}
        responseType={Config.responseType}
        grant_type={Config.grant_type}
        scope='openid profile email'
      >
        <Router basename={window.location.pathname + '#'}>
          <StyledApp>
            <Header />
            <FixedGrid container spacing={5}>
              <Grid item xs={2}>
                <NavBar />
              </Grid>
              <Grid item xs={10}>
                <Switch>
                  <Route path='/' exact component={Dashboard} />
                  <Route path='/dashboard' exact component={Dashboard} />
                  <Route path='/users/' exact component={UserOverview} />
                  <Route path='/users/update/:id' component={UserUpdate} />
                  <Route path='/documents/' exact component={DocumentOverview} />
                  <Route path='/documents/create/' render={(props) => <DocumentEdit key='create' {...props} />} />
                  <Route path='/documents/update/:id' render={(props) => <DocumentEdit key='edit' {...props} />} />
                  <Route path='/permissionGroups/' exact component={PermissionGroupOverview} />
                  <Route path='/permissionGroups/create/' component={PermissionGroupEdit} />
                  <Route path='/permissionGroups/update/:id' component={PermissionGroupEdit} />
                  <Route path='/tags/' exact component={TagOverview} />
                  <Route path='/tags/create' exact component={TagEdit} />
                  <Route path='/tags/update/:id' component={TagEdit} />
                  <Route path='/permissions' exact component={Permissions} />
                  <Route path='/permissions/create/' component={PermissionEdit} />
                  <Route path='/permissions/update/:id' component={PermissionEdit} />
                  <Route render={() => (
                    <Redirect to='/' />
                  )}
                  />
                </Switch>
              </Grid>
            </FixedGrid>
            <Footer />
          </StyledApp>
        </Router>
      </Auth0Provider>
    </EmotionStyleCacheContainer>
  )
}

export default App
