import Session from './Session'
import Request from './Request'
import defaultConfig from './Config'

let config = { ...defaultConfig }

export const buildUrl = (url) => {
  return `${config.backend}${url}`
}

const Api = () => {
  return {
    exec: async (url, params) => {
      const session = Session()
      params = session.append(params)
      const request = Request()
      const res = await request.exec(`${config.backend}${url}`, params)
      /**
       * Force Logout if jwt is not valid
       */
      if (res.httpStatus && (res.httpStatus === 400 || res.httpStatus === 401) && (res.error === 'invalid token' || res.error === 'invalid signature' || res.error === 'jwt expired')) {
        console.log('jwt seems invalid, force logout')
        session.destroy()
        window.location.reload()
      }
      return res
    },
    download: async (url, params) => {
      const session = Session()
      params = session.append(params)
      const request = Request()
      const res = request.download(`${config.backend}${url}`, params)
      return res
    },
    setConfig: ({ backend, prefix }) => {
      config = { backend, prefix }
    },
    buildUrl
  }
}

export default Api
