import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const OverviewTableItem = ({ user, filtered, newUser }) => {
  const betterCreatedAt = () => {
    const date = new Date(user.createdAt)
    if (date.getDate()) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const dateString = date.toLocaleDateString('de-DE', options)
      return dateString
    }
    console.error('invalid createdAt for user:', user)
    return 'Undefined'
  }
  const filteredCreatedAt = (newUser) => {
    const date = new Date(newUser[3])
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    const dateString = date.toLocaleDateString('de-DE', options)
    return dateString
  }
  const history = useHistory()

  return (
    <tr onClick={() => history.push(`/users/update/${user.id}`)}>
      {!filtered &&
        <>
          <td>{user.customer_no}</td>
          <td>{user.company}</td>
          <td>{user.firstname}</td>
          <td>{user.lastname}</td>
          <td>{user.customPermissionsAssigned}</td>
          <td>{betterCreatedAt()}</td>
        </>}
      {filtered &&
        <>
          <td>{newUser[0]}</td>
          <td>{newUser[1]}</td>
          <td>{newUser[2]}</td>
          <td>{filteredCreatedAt(newUser)}</td>
        </>}
    </tr>

  )
}

OverviewTableItem.propTypes = {
  user: PropTypes.any
}

export default OverviewTableItem
