import React from 'react'

const SvgTrash = props => (
  <svg viewBox='0 0 14 18' {...props}>
    <defs>
      <path
        d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'
        id='trash_svg__a'
      />
    </defs>
    <use
      fill={props.fill}
      xlinkHref='#trash_svg__a'
      transform='translate(-5 -3)'
      fillRule='evenodd'
    />
  </svg>
)

export default SvgTrash
