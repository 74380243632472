const Translations = {
  de: {
    'email is a required parameter for this action': 'Bitte geben Sie eine E-Mail-Adresse an.',
    'password is a required parameter for this action': 'Bitte geben Sie ein Passwort an.',
    'salutation is a required parameter for this action': 'Bitte geben Sie eine Anrede an.',
    'firstname is a required parameter for this action': 'Bitte geben Sie einen Vornamen an.',
    'lastname is a required parameter for this action': 'Bitte geben Sie einen Nachnamen an.',
    'company is a required parameter for this action': 'Bitte geben Sie eine Firma an.',
    'language is a required parameter for this action': 'Bitte geben Sie eine Sprache an.',
    'email must be unique': 'Diese E-Mail-Adresse ist bereits in benutzung.',
    'the title for at least one language must be given. i.e. title_en': 'Bitte geben Sie mindestens einen Titel an.',
    'type must be one of brand, product, docart, application, industry, material, group': 'Der Tag muss einen der Folgenden Typen angehören: Marke, Produkt, Dokumentenart, Anwendung, Industrie, Material oder Gruppe',
    'type is a required parameter for this action': 'Bitte wählen Sie eine Kategorie aus.',
    'abbreviation is a required parameter for this action': 'Bitte geben Sie ein Kürzel an',
    'missing title': 'Bitte hinterlegen Sie für jede Sprache einen Titel',
    'tag not found': 'Tag wurde nicht gefunden.',
    'unknown username or password': 'Email-Adresse oder Passwort falsch',
    'No Youtube entry allowed for documents with file uploads.': 'Es kann keine Youtube-ID und ein Dokument zeitgleich hinterlegt werden.',
    'No uploads allowed for Youtube documents.': 'Es kann keine Youtube-ID und ein Dokument zeitgleich hinterlegt werden.',
    'password must have at least 10 characters and consist of at least one uppercase, lowercase letter, a digit and a special character': 'Das Passwort muss mindestens 10 Zeichen haben, davon jeweils mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
    'only mimetype image/jpeg accepted': 'Das Format der ausgewählten Datei wird nicht unterstützt. Bitte laden Sie die Datei im JPEG Format hoch.',
    'At least one chunk failed': 'Es gab einen Fehler beim Hochladen. Bitte versuchen Sie es erneut',
    'This page is asking you to confirm that you want to leave — information you\'ve entered may not be saved.': 'Diese Seite bittet um Bestätigung, dass Sie die Seite verlassen möchten - von Ihnen eingegebene Informationen werden unter Umständen nicht gespeichert.',
    'File type must be image/jpeg': 'Dateityp wird nicht unterstützt. Bitte wählen Sie eine JPG-Datei aus.',
    unauthorized: 'Sie sind hierfür nicht berechtigt. Bitte loggen Sie sich mit einem Adminaccount ein.',
    brands: 'Brands',
    productgroup: 'Produktgruppe',
    productsubgroup: 'Produktuntergruppe',
    topics: 'Themen',
    materials: 'Materialien',
    products: 'Produkte',
    docarts: 'Dokumentart',
    industries: 'Industrien',
    applications: 'Applikationen',
    themes: 'Themen',
    meta: 'Meta',
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französisch',
    it: 'Italienisch'
  },
  en: {
    'email is a required parameter for this action': 'Email is required to create an user',
    'salutation is a required parameter for this action': 'Salutation is required to create an user',
    'firstname is a required parameter for this action': 'Firstname is required to create an user',
    'lastname is a required parameter for this action': 'Lastname is required to create an user',
    'company is a required parameter for this action': 'Company is required to create an user',
    'language is a required parameter for this action': 'Language is required to create an user',
    'email must be unique': 'Email adress is already in use',
    'the title for at least one language must be given. i.e. title_en': 'At least one title must be set',
    'type must be one of brand, product, docart, application, industry, material, group': 'The type of the tag must be one of: brand, product, docart, application, industry, material, group',
    'only mimetype image/jpeg accepted': 'The chosen images\' filetype is not supported. Please upload a JPEG image.',
    'At least one chunk failed': 'Upload failed. Please try again.',
    'This page is asking you to confirm that you want to leave — information you\'ve entered may not be saved.': 'This page is asking you to confirm that you want to leave — information you\'ve entered may not be saved.',
    'File type must be image/jpeg': 'Filetype is not supported. Please choose a JPG file.',
    unauthorized: 'You aren´t authorized. Please login as administrator.',
    productgroup: 'product',
    productsubgroup: 'Productcode',
    topics: 'Topics',
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian'
  }
}

export default Translations
