const Request = () => {
  return {
    exec: async (url, params) => {
      /* Parse params.body to query string, append it to URL and remove the body from params */
      if (params.method === 'GET' && params.body && params.body.length > 0) {
        const getParams = JSON.parse(params.body)
        const queryString = Object.keys(getParams).map(key => key + '=' + getParams[key]).join('&')
        delete params.body
        url = url + '?' + queryString
      }
      const res = await window.fetch(url, params)
      const resultJson = await res.json()
      const result = { ...resultJson, httpStatus: res.status }
      return result
    },
    download: async (url, params) => {
      /* Parse params.body to query string, append it to URL and remove the body from params */
      if (params.method === 'GET' && params.body && params.body.length > 0) {
        const getParams = JSON.parse(params.body)
        const queryString = Object.keys(getParams).map(key => key + '=' + getParams[key]).join('&')
        delete params.body
        url = url + '?' + queryString
      }
      const res = await window.fetch(url, params)
      return res
    }
  }
}

export default Request
