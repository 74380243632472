import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Spacer from '../common/Spacer'
import OverviewTable from './OverviewTable'
import OverviewRefine from './OverviewRefine'
import InfoBar from '../common/EntryInfoBar'
import Button from '../common/Button'
import LoadIndicator from '../common/LoadIndicator'
import Users from '../common/ApiHandler/User'

const Overview = () => {
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [options, setOptions] = useState({
    pageSize: 10,
    increasePageSize: 10
  })
  const [loading, setLoading] = useState(false)

  const setPageSize = (pageSize) => {
    setOptions(prevOptions => ({ ...prevOptions, pageSize }))
  }

  const loadMore = () => {
    setPageSize(options.pageSize + options.increasePageSize)
  }

  const prepareDataForSearch = (users) => {
    const preparedArray = []
    if (Array.isArray(users) && users.length > 0) {
      users.forEach((user) => {
        user.complete = user.company + ' ' + user.customer_no + ' ' + user.firstname + ' ' + user.lastname
        preparedArray.push(user)
      })
    }
    return preparedArray
  }

  const reload = async () => {
    setLoading(true)
    const API = Users({})
    const params = { per_page: 100, page: 0 }
    let allUsers = []
    let requestLength
    do {
      let users = []
      try {
        users = await API.findAll(params)
        allUsers = allUsers.concat(users)
      } catch (e) {
        console.error(e)
      }
      requestLength = users.length
      params.page += 1
    }
    while (requestLength === 100)

    if (allUsers === 'Expired token received for JSON Web Token validation') {
      console.error('user request failed, expired token:', allUsers)
      setLoading(false)
      return
    }
    const allUserArray = Object.values(allUsers).map(user => (
      {
        id: user.user_id,
        company: user.user_metadata ? user.user_metadata.company : 'Undefined',
        createdAt: user.created_at,
        customer_no: user.user_metadata ? (user.user_metadata.customer_no ? user.user_metadata.customer_no : user.user_metadata.customerId) : 'Undefined',
        firstname: user.user_metadata ? user.user_metadata.firstname : 'Undefined',
        lastname: user.user_metadata ? user.user_metadata.lastname : 'Undefined',
        customPermissionsAssigned: user.app_metadata ? (user.app_metadata.customPermissionsAssigned ? 'verrechtet' : 'nicht verrechtet') : 'nicht verrechtet',
        app_metadata: user.app_metadata
      }))
    const preparedUsers = prepareDataForSearch(allUserArray)
    setUsers(preparedUsers)
    setFilteredUsers(preparedUsers)
    setLoading(false)
  }

  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [])

  const visibleEntryCount = filteredUsers.length > options.pageSize ? options.pageSize : filteredUsers.length

  return (
    <Grid>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Spacer />
          <h1>Alle Händler</h1>
        </Grid>
      </Grid>
      <OverviewRefine users={users} setFilteredUsers={setFilteredUsers} reload={reload} />
      <Grid item xs={12}>
        <Spacer height='40px' />
        <h3>Ihre Ergebnisse</h3>
        {!loading &&
          <OverviewTable users={filteredUsers} options={options} />}
        {loading &&
          <LoadIndicator />}
        <Grid item xs={12}>
          {!loading &&
            <InfoBar
              totalEntryCount={users.length}
              visibleEntryCount={visibleEntryCount}
            />}
          <Spacer />
          {options.pageSize < users.length &&
            <>
              <Button onClick={() => loadMore()}>Mehr Laden</Button>
              <Spacer />
            </>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Overview
