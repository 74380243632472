import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Spacer from '../common/Spacer'
import Modal from '../common/NotificationModal'
import UserFormfields from './FormFields'
import Button from '../common/Button'
import Users, { User } from '../common/ApiHandler/User'
import Translation from '../common/BasicTranslation'

import Permissions, { Permission } from '../common/ApiHandler/Permission'

import PermissionSelection from '../Permissions/PermissionSelection'

const UserUpdate = (props) => {
  const [user, setUser] = useState({
    salutation: '',
    company: '',
    email: '',
    firstname: '',
    lastname: '',
    internalnote: '',
    language: '',
    voucher: '',
    channelapp: false,
    channelportal: false
  })

  const [status, setStatus] = useState({
    edit: true,
    success: false
  })

  const [permissions, setPermissions] = useState([])
  const [errorNotification, setErrorNotification] = useState('')
  const [oldUserPermissions, setOldUserPermissions] = useState([])

  const [selectedPermissions, setSelectedPermissions] = useState([])

  const permissionIds = []

  // Load data from API
  const { match } = props
  const userId = match.params.id

  const formatUser = (loadedUser) => {
    setUser({
      id: loadedUser.user_id,
      language: loadedUser.user_metadata ? loadedUser.user_metadata.language : 'Undefined',
      company: loadedUser.user_metadata ? loadedUser.user_metadata.company : 'Undefined',
      createdAt: loadedUser.created_at,
      customer_no: loadedUser.user_metadata ? loadedUser.user_metadata.customer_no : 'Undefined',
      firstname: loadedUser.user_metadata ? loadedUser.user_metadata.firstname : 'Undefined',
      lastname: loadedUser.user_metadata ? loadedUser.user_metadata.lastname : 'Undefined',
      ilias: loadedUser.user_metadata ? loadedUser.app_metadata.ilias : false,
      ...loadedUser
    })
  }

  const loadData = async () => {
    const currentProfile = Users({ id: userId })
    const loadedUserData = await currentProfile.findOne(userId)
    if (!loadedUserData.app_metadata) {
      loadedUserData.app_metadata = {
        ilias: false
      }
    }
    formatUser(loadedUserData)

    // Add permissions to user
    const originalPermissions = []
    const allPermissionData = loadedUserData.permissions
    if (allPermissionData) {
      allPermissionData.forEach((permission) => {
        originalPermissions.push(permission.id)
      })
    }
    const originalPermissiongroups = []
    const allPermissiongroupData = loadedUserData.permissionGroups
    if (allPermissiongroupData) {
      allPermissiongroupData.forEach((permissiongroup) => {
        originalPermissiongroups.push(permissiongroup.id)
      })
    }
    setOldUserPermissions(originalPermissions)
    setSelectedPermissions(originalPermissions)
    await loadPermissionData()
  }

  const handlePermissionChange = async (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(selectedPermissions.filter(perm => perm !== permission))
    } else {
      setSelectedPermissions(prevSelectedPermissions => [...prevSelectedPermissions, permission])
    }
  }

  const loadPermissionData = async () => {
    const API = Permissions({})
    const allPermissions = await API.search()
    setPermissions(allPermissions)
    getPermissionsIds(allPermissions)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  const getPermissionsIds = (permissions) => {
    // eslint-disable-next-line
    for (const permission of permissions) {
      permissionIds.push(permission.id)
      if (permission.children.length > 0) {
        getPermissionsIds(permission.children)
      }
    }
  }

  const submit = async () => {
    user.app_metadata.ilias = user.ilias || false
    const newAssignedPermissions = getAssignedPermissions()
    if (newAssignedPermissions.length > 0) {
      user.app_metadata.customPermissionsAssigned = true
    }
    const currentProfile = User({ id: userId, appMetadata: user.app_metadata })
    const res = await currentProfile.save()
    if (typeof res === 'object') {
      const API = Permission({})
      getUnassignedPermissions().forEach(async (permissionId) => {
        await API.unassignUser(user.id, permissionId)
      })
      newAssignedPermissions.forEach(async (permissionId) => {
        await API.assignUser(user.id, permissionId)
      })
      setStatus(prevStatus => ({ ...prevStatus, success: true }))
    } else {
      setErrorNotification(Translation(res))
    }
  }

  const getUnassignedPermissions = () => {
    return oldUserPermissions.filter(id => !selectedPermissions.includes(id))
  }

  const getAssignedPermissions = () => {
    return selectedPermissions.filter(id => !oldUserPermissions.includes(id))
  }

  const closeModal = () => {
    setStatus(prevStatus => ({ ...prevStatus, success: false }))
  }

  return (
    <Grid>
      {status.success && status.edit &&
        <Modal title='Glückwunsch' message='Erfolgreich editiert' close={e => closeModal()}>
          <Grid item xs={12}>
            <Button to='/users/'>Benutzerübersicht</Button>
          </Grid>
        </Modal>}
      <Spacer />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h1>Händler bearbeiten</h1>
          <Spacer height='30px' />
        </Grid>
      </Grid>
      {user && user.app_metadata &&
        <UserFormfields user={user} setUser={setUser} type='update' errorNotification={errorNotification}>
          <Grid item xs={12}>
            <Spacer height='20px' />
            <h3>Berechtigungen</h3>
            <Spacer height='20px' />
          </Grid>
          {permissions.length &&
            <PermissionSelection defaultPermissions={oldUserPermissions} onChange={handlePermissionChange} />}
          <Spacer />
          <Button onClick={() => submit()}>speichern</Button>
        </UserFormfields>}
    </Grid>
  )
}

UserUpdate.propTypes = {
  match: PropTypes.any
}

export default UserUpdate
